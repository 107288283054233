import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { moveToCheckoutAction } from './actions/moveToCheckout.action';
import { moveToSaveForLaterAction } from './actions/moveToSaveForLater.action';
import {
	getCheckoutItems,
	getCheckoutItemsFulfilledAction,
	getCheckoutItemsPendingAction,
	getCheckoutItemsRejectedAction
} from './thunks/getCheckoutItems';

export const checkoutItemsAdapter = createEntityAdapter();

export const initialState = checkoutItemsAdapter.getInitialState({
	isLoading: true,
	items: [],
});

export const checkoutSlice = createSlice({
	name: 'checkout',
	initialState,
	reducers: {
		moveToCheckout: moveToCheckoutAction,
		moveToSaveForLater: moveToSaveForLaterAction,
	},
	extraReducers: (builder) => {
		builder.addCase(getCheckoutItems.pending, getCheckoutItemsPendingAction);
		builder.addCase(getCheckoutItems.fulfilled, getCheckoutItemsFulfilledAction);
		builder.addCase(getCheckoutItems.rejected, getCheckoutItemsRejectedAction);
	},
});

export const checkoutSliceActions = checkoutSlice.actions;
export const { moveToCheckout, moveToSaveForLater } = checkoutSlice.actions;
export default checkoutSlice.reducer;
