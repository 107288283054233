import { createSlice } from '@reduxjs/toolkit';
import { MARKETPLACE_TAB } from 'constants/marketplace';
import { LIST_KEYS } from 'store/slices/buyerVehicles/helpers/vehicles';
import { getSavedSearch } from './savedSearchThunks';
import { toggleSavedSearchNotifications } from './helpers';

export const initialSearchListFilters = {
	getSavedSearchInProgress: false,
	getSavedSearchErrorMessage: '',
	savedSearches: [],
	modals: {
		createOrUpdateModalIsOpened: false,
		deleteSearch: {
			isOpened: false,
			search: '',
		},
	},
};

export const initialState = {
	searchLists: {
		[MARKETPLACE_TAB.marketplace]: initialSearchListFilters,
		[MARKETPLACE_TAB.offers]: initialSearchListFilters,
		[MARKETPLACE_TAB.watchlist]: initialSearchListFilters,
		[MARKETPLACE_TAB.hidden]: initialSearchListFilters,
		[LIST_KEYS.auction]: initialSearchListFilters,
		[LIST_KEYS.offers]: initialSearchListFilters,
		[LIST_KEYS.hidden]: initialSearchListFilters,
		[LIST_KEYS.watchlist]: initialSearchListFilters,
	},
};

const getSearchList = ({ state, searchListKey }) => {
	return state.searchLists[searchListKey];
};

export const savedSearch = createSlice({
	name: 'savedSearch',
	initialState,
	reducers: {
		toggleCreateOrUpdateSavedSearchModal: (state, { payload }) => {
			const { isOpened, listKey } = payload;
			getSearchList({ state, searchListKey: listKey }).modals.createOrUpdateModalIsOpened = isOpened;
		},
		createOrUpdateSavedSearch: (state, { payload }) => {
			const { search: newSearch, listKey } = payload;
			const searchList = getSearchList({ state, searchListKey: listKey });
			let existingSavedSearchIndex = searchList.savedSearches.findIndex(
				(currentSearch) => currentSearch.id === newSearch.id,
			);
			if (existingSavedSearchIndex !== -1) {
				searchList.savedSearches[existingSavedSearchIndex] = newSearch;
			} else {
				searchList.savedSearches.push(newSearch);
			}
		},
		toggleDeleteSearchModal: (state, { payload }) => {
			const { isOpened, listKey, search } = payload;
			const searchList = getSearchList({ state, searchListKey: listKey });
			searchList.modals.deleteSearch.isOpened = isOpened;
			searchList.modals.deleteSearch.search = search;
		},
		toggleSavedSearchNotification: (state, { payload }) => {
			const { savedSearchId } = payload;
			// it applies to any tab where filter exist
			Object.entries(state.searchLists).forEach(([listKey]) => {
				getSearchList({ state, searchListKey: listKey }).savedSearches = toggleSavedSearchNotifications({
					savedSearches: getSearchList({ state, searchListKey: listKey }).savedSearches || [],
					savedSearchId,
				});
			});
		},
		deleteSavedSearch: (state, { payload: { search } }) => {
			Object.entries(state.searchLists).forEach(([listKey]) => {
				const searchList = getSearchList({ state, searchListKey: listKey });
				searchList.savedSearches = searchList.savedSearches.filter((filter) => filter.id !== search.id);
			});
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSavedSearch.pending, (state, { meta }) => {
			const searchList = getSearchList({ state, searchListKey: meta.arg.searchListKey });
			searchList.getSavedSearchInProgress = true;
			searchList.getSavedSearchErrorMessage = '';
		});
		builder.addCase(getSavedSearch.fulfilled, (state, { payload, meta }) => {
			const { savedSearches } = payload;
			const searchList = getSearchList({ state, searchListKey: meta.arg.searchListKey });
			searchList.getSavedSearchInProgress = false;
			searchList.savedSearches = savedSearches;
		});
		builder.addCase(getSavedSearch.rejected, (state, { meta }) => {
			const searchList = getSearchList({ state, searchListKey: meta.arg.searchListKey });
			searchList.getSavedSearchInProgress = false;
			searchList.getSavedSearchErrorMessage = 'something failed loading saved searches';
		});
	},
});

export const {
	toggleCreateOrUpdateSavedSearchModal,
	createOrUpdateSavedSearch,
	toggleDeleteSearchModal,
	toggleSavedSearchNotification,
	deleteSavedSearch,
} = savedSearch.actions;
export default savedSearch.reducer;
