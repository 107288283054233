import crypto from 'crypto';

export const encrypt = (text, alg, key, iv) => {
	var cipher = crypto.createCipheriv(alg, key, iv);
	var crypted = cipher.update(text,'utf-8','base64');
	crypted += cipher.final('base64');
	return crypted;
};

export const decrypt = (text, alg, key, iv) => {
	var cipher = crypto.createDecipheriv(alg, key, iv);
	var crypted = cipher.update(text, 'base64', 'utf-8');
	crypted += cipher.final('utf-8');
	return crypted;
};
