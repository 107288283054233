import axios from 'axios';
import STATUS_CODES from 'constants/statusCodes';
import JsonApiDeserializer from 'jsona';
import qs from 'qs';
import { notifyError } from '../../helpers/errorNotifier';
import { addAuthInterceptor } from './interceptors/addAuthInterceptor';
import { setupRedirectInterceptor } from './interceptors/redirectInterceptor';
import { requestDuration, requestStartTime } from './interceptors/requestTime';

export const formatResponse = (data, serializationType) => {
	if (!data) return {};

	try {
		const parsedData = JSON.parse(data);
		let deserializedData;

		switch (serializationType) {
			case 'json:api':
				const formatter = new JsonApiDeserializer();
				deserializedData = {
					data: formatter.deserialize(parsedData),
					...(parsedData.meta && { meta: parsedData.meta }),
				};
				break;
			default:
				deserializedData = parsedData;
		}

		return deserializedData;
	} catch (e) {
		throw new Error(`Error formatting data using type "${serializationType}": ${e}. \nUnformatted data: ${data}`);
	}
};

export const restMethods = Object.freeze({
	PUT: 'put',
	POST: 'post',
	GET: 'get',
	DELETE: 'delete',
	PATCH: 'patch',
});

const { PUT, POST, GET, DELETE, PATCH } = restMethods;

export const defaultConfigurationParam = Object.freeze({
	withCredentials: true, // need it for utm tracking, not only for auth.
	credentials: process.env.ENV !== 'production' ? 'include' : 'same-origin',
	withAutoAuthentication: false,
	sendAuthHeaders: false,
});

const getRelativeDefaultBaseUrl = () => {
	if (typeof window !== 'undefined') return process.env.RELATIVE_BASE_URL;
	return process.env.RESTURL_BASE;
};

export class RestClient {
	constructor(config = defaultConfigurationParam, serializationType = 'rest') {
		const defaultBasedURL = getRelativeDefaultBaseUrl();

		this.config = config;
		this._serializationType = serializationType;
		this._baseUrl = config.baseUrl || defaultBasedURL;

		if (!process.env.NEXT_PUBLIC_NO_AXIOS_TIMEOUT) {
			this._timeout = config.timeout || 30000;
		}

		const defaultConfig = {
			baseURL: this._baseUrl,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Application: 'webapp',
			},
			timeout: this._timeout,
			transformResponse: (data) => formatResponse(data, serializationType),
		};

		const credentialsConfig = {
			...(config.withCredentials && { withCredentials: config.withCredentials }),
			...(config.credentials && { credentials: config.credentials }),
		};

		const axiosConfig = { ...defaultConfig, ...credentialsConfig };

		this._axios = axios.create(axiosConfig);
		addAuthInterceptor(config, this);
		this._axios.interceptors.request.use(requestStartTime);
		this._axios.interceptors.response.use(requestDuration);
		setupRedirectInterceptor(this);
	}

	get(url, params = {}, headers = {}, cancelToken = null) {
		return this._get(url, params, headers, cancelToken);
	}

	_get(url, params = {}, headers = {}, cancelToken = null) {
		const payload = {
			url: url,
			method: GET,
			headers: headers,
			params: params,
			paramsSerializer: (params) => qs.stringify(params),
			...(cancelToken && { cancelToken }),
		};
		return this.makeRequest(payload);
	}

	delete(url, headers = {}, data = {}, cancelToken = null) {
		return this.ajax(url, data, headers, DELETE, cancelToken);
	}

	post(url, data, headers = {}, cancelToken = null) {
		return this.ajax(url, data, headers, POST, cancelToken);
	}

	put(url, data, headers = {}, cancelToken = null) {
		return this.ajax(url, data, headers, PUT, cancelToken);
	}

	patch(url, data, headers = {}, cancelToken = null) {
		return this.ajax(url, data, headers, PATCH, cancelToken);
	}

	ajax(url, data = {}, headers = {}, method, cancelToken) {
		return this.makeRequest({
			url: url,
			baseURL: this._baseUrl,
			headers: headers,
			method: method,
			data: data,
			...(cancelToken && { cancelToken }),
		});
	}

	async makeRequest(requestPayload) {
		console.log(requestPayload);
		try {
			const response = await this._axios.request(requestPayload);
			return {
				status: response.status,
				data: response.data,
				headers: response.headers,
			};
		} catch (error) {
			const errorResponse = this.buildErrorResponse(error);
			this.reportError(errorResponse);
			throw errorResponse;
		}
	}

	setUserToErrorLog() {
		const { user = {} } = this.config;
		const { email, id } = user;
		return (event) => event.setUser(id, email);
	}

	reportError(error) {
		// 401s are intentionally returned by us, so they are expected and we don't need to tell bugsnag
		const statusesToIgnore = [STATUS_CODES.UNAUTHORIZED, STATUS_CODES.FORBIDDEN];

		if (error?.isCancel || statusesToIgnore.includes(error.status)) {
			return;
		}

		notifyError(error, {}, this.setUserToErrorLog());
	}

	buildErrorResponse(error) {
		const defaultStatus = STATUS_CODES.INTERNAL_SERVER_ERROR;
		const result = {};
		result.isCancel = axios.isCancel(error);

		if (error.response) {
			/*
			 * The request was made and the server responded with a
			 * status code that falls out of the range of 2xx
			 */
			result.error = error.response.data || error.response.message;
			result.status = error.response.status;
		} else if (error.request) {
			/*
			 * The request was made but no response was received, `error.request`
			 * is an instance of XMLHttpRequest in the browser and an instance
			 * of http.ClientRequest in Node.js
			 */
			result.status = error.request.status;
			result.error = error.request;
		} else {
			// Something happened in setting up the request and triggered an Error
			result.status = defaultStatus;
			result.error = error.message;
		}

		result.status = result.status || defaultStatus;

		return result;
	}

	getCancelToken() {
		return axios.CancelToken.source();
	}
}

// here we export the most commons configuration
// the idea is to use same instance for same configuration so please avoid to create RestClient instances if not needed
export const authenticatedRestClient = new RestClient({
	...defaultConfigurationParam,
	withAutoAuthentication: true,
	withCredentials: true,
	sendAuthHeaders: true,
});

/**
 * This instance will not send the Authorization header.
 */
export const withCookiesRestClient = new RestClient({
	...defaultConfigurationParam,
	withAutoAuthentication: true,
	withCredentials: true,
	credentials: process.env.ENV === 'development' ? 'include' : 'same-origin',
});
