import Head from 'next/head';

export const BlcFavicon = () => {
	const path = '/images/logos/openlane';
	return (
		<Head>
			<link href={`${path}/favicon-16x16.png`} rel="icon" sizes="16x16" type="image/png" key="favicon-16" />
			<link href={`${path}/favicon-32x32.png`} rel="icon" sizes="32x32" type="image/png" key="favicon-32" />
			<link href={`${path}/favicon-96x96.png`} rel="icon" sizes="96x96" type="image/png" key="favicon-96" />
		</Head>
	);
};
