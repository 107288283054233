import { colors } from '@Backlot-Cars/archie';
import { media } from 'public/styles';
import styled from 'styled-components';


export const TopBar = styled.div`
	display: flex;
	width: 100%;
	background-color: ${colors.white};
	align-items: center;
	justify-content: flex-start;
	height: 113px;
	padding-left: 40px;

	@media ${media.smaller} {
		justify-content: center;
		height: 72px;
		transform: scale(0.63);
	}
`;
