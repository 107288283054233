import UserContext from 'hooks/userContext';

const userProvider = (WrappedComponent) => {
	const Wrapper = (props) => {
		return (
			<UserContext.Provider value={{ ...props.user }}>
				<WrappedComponent {...props} />
			</UserContext.Provider>
		);
	};

	return Wrapper;
};

export default userProvider;
