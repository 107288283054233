import STATUS_CODES from 'constants/statusCodes';
import BuyerOffersService from 'services/marketplace/buyside/offers.service';

export const getRefreshDataForVehicleIds = async ({
	vehicles,
	loggedUser,
	searchListKey,
	isFeaturedVehicleCampaign,
}) => {
	if (!vehicles.length) return [];

	const offerRefreshResult = await BuyerOffersService.instance.offerRefresh({
		vehicles,
		loggedUser,
		focussedSearchListKey: searchListKey,
		reason: 'first_load',
		forceRefresh: true,
		isFeaturedVehicleCampaign,
	});
	if (offerRefreshResult?.status !== STATUS_CODES.OK || !offerRefreshResult?.data?.success) {
		throw new Error('Not able to get refreshed data for vehicles');
	}

	const bidSaleIdToVehicleMap = offerRefreshResult.data.data.vehicles.reduce(
		(accumulator, currentValue) => ({
			...accumulator,
			[currentValue.bid_sale_id]: currentValue,
		}),
		{},
	);

	return vehicles.map((vehicle) => ({
		...vehicle,
		...bidSaleIdToVehicleMap[vehicle.bid_sale_id],
	}));
};
