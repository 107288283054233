import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BID_SALE_SOURCE_TAB } from 'constants/bidSale';
import { MARKETPLACE_TAB } from 'constants/marketplace';
import BidSalesService from 'services/timedSales/buyside/bidsales.service';
import { updateSyncedTabsAfterFetch } from 'store/slices/auctionPoolingUpdater/auctionPoolingSlice';
import {
	addToWatchlist,
	fetchWatchlistVehiclesBidIdsSuccess,
	removeFromWatchlist,
	updateBid,
} from 'store/slices/buyerVehicles/buyerVehicles';
import { loadVehiclesMarketplace, unHideVehicle } from 'store/slices/buyerVehicles/buyerVehiclesThunks';
import { LIST_KEYS } from 'store/slices/buyerVehicles/helpers/vehicles';
import { getFiltersParams } from 'store/slices/buyers/filters/filtersSelectors';
import { updateAppliedFilters } from 'store/slices/buyers/filters/filtersSlice';

export const getWatchlistPage = createAsyncThunk('getWatchlistPage', async ({ loggedUser, currentPage }, thunkAPI) => {
	const { rejectWithValue, fulfillWithValue, signal } = thunkAPI;
	const source = axios.CancelToken.source();
	signal.addEventListener('abort', () => {
		source.cancel();
	});

	try {
		const { params } = getFiltersParams(thunkAPI.getState(), LIST_KEYS.watchlist);
		const page = currentPage || params?.page || 1;

		const response = await BidSalesService.instance.getWatchlistPage(page, loggedUser, { ...params });

		const {
			applied_filters: appliedFilters,
			data: { vehicles },
			metadata: { per_page, page_count, total_count },
		} = response.data;

		// Set on pooling slice that this tab was updated data due to fetch
		thunkAPI.dispatch(updateSyncedTabsAfterFetch({ syncedTab: BID_SALE_SOURCE_TAB.watchlist }));
		if (appliedFilters) {
			thunkAPI.dispatch(updateAppliedFilters({ appliedFilters, listKey: LIST_KEYS.watchlist }));
		}

		return fulfillWithValue({
			vehicles,
			itemsPerPage: per_page,
			pageCount: page_count,
			totalElements: total_count,
			currentPage: page,
		});
	} catch (err) {
		return rejectWithValue({ errorMessage: 'There was an error while fetching the vehicles.' });
	}
});

export const addVehicleToWatchlist =
	({ vehicleToWatch, loggedUser }) =>
		async (dispatch, getState) => {
			const {
				buyerVehicles: { entities },
			} = getState();
			const watchlistSearchList = getState().buyerVehicles.searchLists[LIST_KEYS.watchlist];
			const { vehicleIds } = watchlistSearchList;
			const currentPage = watchlistSearchList.currentSearch.params.page;
			const firstVehicleBidSaleId = entities[vehicleIds[0]]?.bid_sale_id;

			// we should unhide vehicle if it was previously hidden
			if (vehicleToWatch.hidden) {
				dispatch(unHideVehicle({ vehicleToUnHide: vehicleToWatch, loggedUser }));
			}

			// get page when the element was added into previous page, so current page was shifted
			if (vehicleIds.length && vehicleToWatch.bid_sale_id > firstVehicleBidSaleId && currentPage !== 1) {
				dispatch(getWatchlistPage({ currentPage, loggedUser }));
			} else {
				dispatch(addToWatchlist({ vehicle: vehicleToWatch }));
			}
		};

export const removeVehicleFromWatchlist =
	({ vehicle, loggedUser, watchlistListKey }) =>
		async (dispatch, getState) => {
			const {
				buyerVehicles: { entities },
			} = getState();
			const watchlistList = getState().buyerVehicles.searchLists[watchlistListKey];
			const currentPage = watchlistList.currentSearch.params.page;
			const { pageCount, vehicleIds } = watchlistList;

			let doNotModifyCurrentPage = false;

			// get page if the current page was "shifted" by deletion on current or previous pages and there are more pages so we have to refresh
			if (
				vehicleIds.length &&
			vehicle.bid_sale_id > entities[vehicleIds[vehicleIds.length - 1]].bid_sale_id &&
			currentPage < pageCount
			) {
				dispatch(getWatchlistPage({ currentPage, loggedUser }));
				doNotModifyCurrentPage = true;
			} else if (
				currentPage > 1 &&
			currentPage === pageCount &&
			vehicleIds.length === 1 &&
			entities[vehicleIds[0]].bid_sale_id === vehicle.bid_sale_id
			) {
				dispatch(getWatchlistPage({ currentPage: currentPage - 1, loggedUser }));
				doNotModifyCurrentPage = true;
			}
			// remove from watchlist
			dispatch(removeFromWatchlist({ vehicle, doNotModifyCurrentPage, watchlistListKey }));
		};

export const getAllWatchlistPagesForNotifications = () => async (dispatch) => {
	try {
		const {
			data: { data: watchlistVehiclesBidIds },
		} = await BidSalesService.instance.getWatchlistBidSaleIds();
		dispatch(fetchWatchlistVehiclesBidIdsSuccess({ watchlistVehiclesBidIds }));
	} catch (err) {}
};

export const updateMarketplaceVehicleFromWatchlist =
	({ loggedUser, closedTradeNetwork, unknownError = 'Something failed', removeFromWatchlist = false, vehicle }) =>
		async (dispatch, getState) => {
			const watchlistSearchKey = MARKETPLACE_TAB.watchlist;

			const {
				pageCount,
				vehicleIds,
				currentSearch: {
					params: { page: currentPage },
				},
			} = getState().buyerVehicles.searchLists[watchlistSearchKey];

			let isLastVehicleOfPage = currentPage > 1 && currentPage === pageCount && vehicleIds.length === 1;

			if (vehicle.hidden && !removeFromWatchlist) {
				dispatch(
					unHideVehicle({
						vehicleToUnHide: vehicle,
						loggedUser,
						searchListKey: watchlistSearchKey,
					}),
				);
			}

			dispatch(updateBid({ id: vehicle.id, changes: { watching: !removeFromWatchlist }, loggedUser }));
			// TODO: we should handle watchlist addition/removal on FE avoiding unnecessary calls (we have it for auctions)
			dispatch(
				loadVehiclesMarketplace({
					loggedUser,
					closedTradeNetwork,
					errorMessage: unknownError,
					searchListKey: watchlistSearchKey,
					page: removeFromWatchlist && isLastVehicleOfPage ? currentPage - 1 : currentPage,
				}),
			);
		};
