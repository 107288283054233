import { BID_SALE_SOURCE_TAB } from 'constants/bidSale';
import { MARKETPLACE_TAB } from 'constants/marketplace';
import { LIST_KEYS } from 'store/slices/buyerVehicles/helpers/vehicles';

export const isWatchlistFocussed = (focussedSourceTab) => focussedSourceTab === BID_SALE_SOURCE_TAB.watchlist;

export const insertOrdered = (watchlistVehiclesBidIds, newBidIdToInsert) => {
	let newElementIndex = 0;
	while (
		newElementIndex < watchlistVehiclesBidIds.length &&
		watchlistVehiclesBidIds[newElementIndex] > newBidIdToInsert
	) {
		newElementIndex++;
	}
	watchlistVehiclesBidIds.splice(newElementIndex, 0, newBidIdToInsert);
};

// it mutates the original array
export const insertVehicleIntoIdslist = (entities, vehicleIds, newVehicle, itemsPerPage) => {
	let newElementIndex = 0;
	while (
		newElementIndex < vehicleIds.length &&
		entities[vehicleIds[newElementIndex]].bid_sale_id > newVehicle.bid_sale_id
	) {
		newElementIndex++;
	}
	vehicleIds.splice(newElementIndex, 0, newVehicle.id);
	// keep size if needed
	if (vehicleIds.length > itemsPerPage) vehicleIds.pop();
};

export const calculatePages = (totalElements, itemsPerPage) => Math.ceil(totalElements / itemsPerPage);

export const calculatePaginationAfterAddition = (totalElements, itemsPerPage) => {
	const updatedTotalElements = totalElements + 1;
	return {
		totalElements: updatedTotalElements,
		pageCount: calculatePages(updatedTotalElements, itemsPerPage),
	};
};

export const removeFromVehicleIds = ({
	vehicleIds,
	vehicleIdToRemove,
	totalElements = 1,
	itemsPerPage = 30,
	checkPresenceOnVehicleIdsToCalculatePagination = false,
}) => {
	const vehicleIdsFiltered = vehicleIds.filter((vehicleId) => vehicleIdToRemove !== vehicleId);
	let modifiedTotalElements = totalElements - 1;
	if (checkPresenceOnVehicleIdsToCalculatePagination) {
		modifiedTotalElements = vehicleIdsFiltered.length + 1 === vehicleIds.length ? totalElements - 1 : totalElements;
	}
	return {
		vehicleIds: vehicleIdsFiltered,
		totalElements: modifiedTotalElements,
		pageCount: calculatePages(modifiedTotalElements, itemsPerPage),
	};
};

export const removeFromWatchlistVehiclesBidIds = (watchlistVehiclesBidIds, bidIdToRemove) => {
	return watchlistVehiclesBidIds.filter((vehicleBidSaleId) => vehicleBidSaleId !== bidIdToRemove);
};

export const filterWatchedVehiclesFromNotificationList = (vehiclesBidIdsWithUpdates, vehiclePageList) => {
	const vehiclePageBidSaleId = vehiclePageList.map((vehicle) => vehicle.bid_sale_id);
	return vehiclesBidIdsWithUpdates.filter((bidSaleId) => !vehiclePageBidSaleId.includes(bidSaleId));
};

export const updatePagesToHighlight = (watchlistVehiclesBidIds, vehiclesBidIdsWithUpdates, itemsPerPage) => {
	const highlightedPages = new Set();
	watchlistVehiclesBidIds.forEach((bidSaleId, index) => {
		const currentPage = Math.ceil((index + 1) / itemsPerPage);
		if (vehiclesBidIdsWithUpdates.includes(bidSaleId)) {
			highlightedPages.add(currentPage);
		}
	});
	return [...highlightedPages];
};

/**
 *
 * @param {string} searchListKey
 * @param {'watchlist' | 'hidden'} action
 */
export const getListKeysForWatchlistAndHiddenActions = (searchListKey) =>
	Object.values(MARKETPLACE_TAB).includes(searchListKey)
		? {
			hiddenListKey: MARKETPLACE_TAB.hidden,
			watchlistListKey: MARKETPLACE_TAB.watchlist,
			sourceListKey: MARKETPLACE_TAB.marketplace,
		  }
		: {
			hiddenListKey: LIST_KEYS.hidden,
			watchlistListKey: LIST_KEYS.watchlist,
			sourceListKey: LIST_KEYS.auction,
		  };
