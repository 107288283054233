import { startingBidBubble, userOfferOnPendingStatusBubble } from '../bubbles';

const getPendingOfferStatus = (currentUserData, myOfferAmount) => {
	return {
		bidActions: true,
		...(myOfferAmount ? { ...userOfferOnPendingStatusBubble, ...currentUserData } : { ...startingBidBubble }),
	};
};

export default getPendingOfferStatus;
