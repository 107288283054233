export const getPathFromURL = (url, defaultOnError) => {
	const defaultValue = defaultOnError || '/';
	try {
		const urlObj = new URL(url, process.env.BASE_URL);
		return `${urlObj.pathname}${urlObj.search}` || defaultValue;
	} catch (e) {
		return defaultValue;
	}
};

export const createAbsoluteUrlFromPath = (urlPath) => {
	let baseUrl = process.env.BASE_URL;
	if (typeof window !== 'undefined' && window?.location?.origin) {
		baseUrl = window.location.origin;
	}
	return `${baseUrl}${urlPath}`;
};

export const couldBePath = (path) => path.startsWith('/');

export const ensurePathToBeValid = (path) => (couldBePath(path) ? path : `/${path}`);
