import { toast } from '@Backlot-Cars/archie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BID_SALE_SOURCE_TAB } from 'constants/bidSale';
import { getMarketplaceSourceTabFromTabName, MARKETPLACE_TAB } from 'constants/marketplace';
import STATUS_CODES from 'constants/statusCodes';
import BidSalesService from 'services/timedSales/buyside/bidsales.service';
import BuysideSearchService from 'services/timedSales/buyside/search.service';
import { getListKeysForWatchlistAndHiddenActions } from 'store/helpers/watchlist';
import { updateSyncedTabsAfterFetch } from 'store/slices/auctionPoolingUpdater/auctionPoolingSlice';
import { setAuctionsStatus } from 'store/slices/auctionStatus/slice';
import { updateAppliedFilters } from 'store/slices/buyers/filters/filtersSlice';
import { LIST_KEYS } from 'store/slices/buyerVehicles/helpers/vehicles';
import { getRefreshDataForVehicleIds } from 'store/slices/buyerVehicles/thunks/helpers/getOfferRefreshData';
import { CONTEXT } from 'helpers/search';
import { removeVehicleFromWatchlist } from 'store/slices/buyerVehicles/thunks/watchlist';
import { loggedUSerSelector } from '../loggedUsers/selector';
import * as localFunctions from './buyerVehiclesThunks';
import { shouldLoadFeaturedVehicleCampaign, loadFeaturedVehicleCampaign } from './thunks/featuredVehicles';
import { getFiltersParams } from 'store/slices/buyers/filters/filtersSelectors';

const NUMBER_OF_HIDE_ACTIONS_BEFORE_RELOAD_AUCTION_LIST = 10;

export const loadVehicles = createAsyncThunk(
	'loadVehicles',
	async ({ loggedUser, sourceTab = BID_SALE_SOURCE_TAB.auction, page, searchListKey }, thunkAPI) => {
		const source = axios.CancelToken.source();
		thunkAPI.signal.addEventListener('abort', () => {
			source.cancel();
		});

		const { params: searchParams } = getFiltersParams(thunkAPI.getState(), searchListKey);
		const isOfferList = searchListKey === BID_SALE_SOURCE_TAB.offers;

		const shouldApplySearchFilters = sourceTab !== BID_SALE_SOURCE_TAB.hidden;
		let filters = {
			source_tab: sourceTab,
		};

		if (shouldApplySearchFilters) {
			filters = {
				...searchParams,
				...filters,
			};
		}

		const params = { ...filters, page: page || searchParams.page };

		let data;
		if (isOfferList) {
			const offersParams = { ...params, context: CONTEXT.auction };
			const { data: offerData } = await BuysideSearchService.instance.getOffers(
				offersParams,
				false,
				source.token,
			);
			data = offerData;
		} else {
			const { data: bidSalesData } = await BidSalesService.instance.getVehicles(loggedUser, params, source.token);
			data = bidSalesData;
		}

		const {
			data: { auctions },
			applied_filters: appliedFilters,
		} = data;

		if (auctions) thunkAPI.dispatch(setAuctionsStatus(auctions)); // Shall we maybe put all of this in the same slice?
		if (appliedFilters) thunkAPI.dispatch(updateAppliedFilters({ appliedFilters, listKey: searchListKey }));

		// Set on pooling slice that this tab was updated data due to fetch
		thunkAPI.dispatch(updateSyncedTabsAfterFetch({ syncedTab: sourceTab }));

		return data;
	},
);

const getPageToReloadAfterSuccessfullHide = (
	itemsPerPage,
	itemsOnCurrentPage,
	totalElements,
	currentPage,
	pageCount,
) => {
	const isOnLastPage = currentPage === pageCount;
	const itemsOnCurrentPageAfterHide = itemsOnCurrentPage - 1;
	const thereAreMoreElementsOnOtherPages = totalElements - 1 > itemsOnCurrentPageAfterHide;
	const isPageEmpty = itemsOnCurrentPageAfterHide === 0;
	const hiddenActionsNumberExceeded =
		itemsOnCurrentPageAfterHide <= itemsPerPage - NUMBER_OF_HIDE_ACTIONS_BEFORE_RELOAD_AUCTION_LIST;

	if (isPageEmpty && thereAreMoreElementsOnOtherPages) {
		return isOnLastPage ? currentPage - 1 : currentPage;
	}
	if (hiddenActionsNumberExceeded && thereAreMoreElementsOnOtherPages && !isOnLastPage) return currentPage;

	return false;
};

export const setVehicleAsHidden = createAsyncThunk(
	'setVehicleAsHidden',
	async ({ vehicle, loggedUser, vdpType, sourceTab, searchListKey }, thunkAPI) => {
		const { watchlistListKey, sourceListKey } = getListKeysForWatchlistAndHiddenActions(searchListKey);

		const {
			data: { success },
		} = await BidSalesService.instance.hideVehicle(vehicle.id, loggedUser, vdpType, sourceTab);

		const vehicleListState = thunkAPI.getState().buyerVehicles;

		// if it's present on watchlist delete it
		if (sourceListKey === LIST_KEYS.auction) {
			const watchlistIds = vehicleListState.searchLists[watchlistListKey].allWatchlistVehiclesBidIds;
			if (watchlistIds.includes(vehicle.bid_sale_id)) {
				thunkAPI.dispatch(removeVehicleFromWatchlist({ vehicle, loggedUser, watchlistListKey }));
			}
		} else if (sourceListKey === MARKETPLACE_TAB.marketplace) {
			const watchlistIds = vehicleListState.searchLists[watchlistListKey].vehicleIds;
			if (watchlistIds.includes(vehicle.id)) {
				thunkAPI.dispatch(removeVehicleFromWatchlist({ vehicle, loggedUser, watchlistListKey }));
			}
		}

		// Reload auction / marketplace list if needed
		const {
			pageCount,
			vehicleIds,
			totalElements,
			itemsPerPage,
			currentSearch: {
				params: { page: currentPage },
			},
		} = vehicleListState.searchLists[sourceListKey];
		const pageToReload = getPageToReloadAfterSuccessfullHide(
			itemsPerPage,
			vehicleIds.length,
			totalElements,
			currentPage,
			pageCount,
		);

		if (pageToReload) {
			// TODO: we should find a way to make request cancelable from different components
			if (sourceListKey === MARKETPLACE_TAB.marketplace) {
				thunkAPI.dispatch(
					localFunctions.loadVehiclesMarketplace({
						page: pageToReload,
						loggedUser,
						searchListKey: MARKETPLACE_TAB.marketplace,
					}),
				);
			} else if (sourceListKey === LIST_KEYS.auction) {
				thunkAPI.dispatch(
					localFunctions.loadVehicles({
						page: pageToReload,
						loggedUser,
						searchListKey: LIST_KEYS.auction,
					}),
				);
			}
		}

		return success;
	},
);

export const unHideVehicle = createAsyncThunk(
	'unHideVehicle',
	async ({ vehicleToUnHide, loggedUser, vdpType, sourceTab, searchListKey }, thunkAPI) => {
		const {
			data: { success },
		} = await BidSalesService.instance.unHideVehicle(vehicleToUnHide.id, loggedUser, vdpType, sourceTab);

		const vehicleListState = thunkAPI.getState().buyerVehicles;

		// TODO: we should find a way to make request cancelable from different components
		const { hiddenListKey, sourceListKey } = getListKeysForWatchlistAndHiddenActions(searchListKey);

		// Reload main source list on unhide (marketplace or auction)
		const pageToReload = vehicleListState.searchLists[sourceListKey].currentSearch.params.page;
		if (sourceListKey === MARKETPLACE_TAB.marketplace) {
			thunkAPI.dispatch(
				localFunctions.loadVehiclesMarketplace({
					page: pageToReload,
					loggedUser,
					searchListKey: MARKETPLACE_TAB.marketplace,
				}),
			);
		} else if (sourceListKey === LIST_KEYS.auction) {
			thunkAPI.dispatch(
				localFunctions.loadVehicles({
					page: pageToReload,
					loggedUser,
					searchListKey: LIST_KEYS.auction,
				}),
			);
		}
		// Reload hidden list if needed
		const { pageCount, vehicleIds } = vehicleListState.searchLists[hiddenListKey];
		const currentPage = vehicleListState.searchLists[hiddenListKey].currentSearch.params.page;
		const thereAreFollowingPages = currentPage < pageCount;
		const deletingLastElementFromPage = currentPage !== 1 && vehicleIds.length === 1;

		if (thereAreFollowingPages || deletingLastElementFromPage) {
			const page = deletingLastElementFromPage ? currentPage - 1 : currentPage;
			if (sourceListKey === MARKETPLACE_TAB.marketplace) {
				thunkAPI.dispatch(
					localFunctions.loadVehiclesMarketplace({
						page: pageToReload,
						loggedUser,
						searchListKey: MARKETPLACE_TAB.hidden,
					}),
				);
			} else if (sourceListKey === LIST_KEYS.auction) {
				thunkAPI.dispatch(
					localFunctions.loadVehicles({
						page,
						sourceTab: BID_SALE_SOURCE_TAB.hidden,
						loggedUser,
						searchListKey: LIST_KEYS.hidden,
					}),
				);
			}
		}
		return success;
	},
);

// ----------------
// Marketplace
// ----------------
export const loadVehiclesMarketplace = createAsyncThunk(
	'loadVehiclesMarketplace',
	async ({ page, closedTradeNetwork, errorMessage, searchListKey, skipVehicleCampaign }, thunkAPI) => {
		const isOfferList = searchListKey === MARKETPLACE_TAB.offers;
		const { params: searchParams } = getFiltersParams(thunkAPI.getState(), searchListKey);

		const { rejectWithValue, fulfillWithValue, signal } = thunkAPI;
		const currentPage = page || searchParams.page || 1;

		if (!skipVehicleCampaign && shouldLoadFeaturedVehicleCampaign({ page: currentPage, searchListKey })) {
			thunkAPI.dispatch(loadFeaturedVehicleCampaign({ searchListKey, context: CONTEXT.marketplace }));
		}

		const source = axios.CancelToken.source();
		signal.addEventListener('abort', () => {
			source.cancel();
		});

		const shouldApplySearchFilters =
			searchListKey !== MARKETPLACE_TAB.hidden && searchListKey !== MARKETPLACE_TAB.watchlist;

		let filters = {
			source_tab: getMarketplaceSourceTabFromTabName(searchListKey),
			page: currentPage,
		};

		if (shouldApplySearchFilters) {
			filters = {
				...searchParams,
				...filters,
			};
		}

		try {
			let searchResult;

			if (isOfferList) {
				searchResult = await BuysideSearchService.instance.getOffers(filters, closedTradeNetwork, source.token);
			} else {
				searchResult = await BuysideSearchService.instance.searchVehicles(
					filters,
					closedTradeNetwork,
					source.token,
				);
			}

			if (searchResult?.status !== STATUS_CODES.OK) return rejectWithValue({ errorMessage });
			if (closedTradeNetwork && searchResult?.data?.messages?.length > 0) {
				return rejectWithValue({ errorMessage: searchResult?.data?.messages[0] });
			}

			const {
				applied_filters: appliedFilters,
				metadata,
				data: { checkout_warning, vehicles, user },
			} = searchResult.data;

			if (checkout_warning?.show) toast.warning(checkout_warning.message);
			if (appliedFilters) thunkAPI.dispatch(updateAppliedFilters({ appliedFilters, listKey: searchListKey }));

			let refreshedVehicles = vehicles;

			refreshedVehicles = await getRefreshDataForVehicleIds({
				vehicles,
				loggedUser: loggedUSerSelector(thunkAPI.getState()),
				searchListKey,
			});

			thunkAPI.dispatch(updateSyncedTabsAfterFetch({ syncedTab: searchListKey }));
			return fulfillWithValue({
				pageCount: metadata.page_count,
				totalCount: metadata.total_count,
				page: Number(metadata.page),
				vehicles: refreshedVehicles,
				user, // this is not being used check it
			});
		} catch (res) {
			return rejectWithValue({ errorMessage });
		}
	},
);

export const getURLParams = (urlParams, notAllowedParams) =>
	Object.keys(notAllowedParams).reduce((prev, key) => {
		if (key in prev) return { ...prev, [key]: notAllowedParams[key] };
		return prev;
	}, urlParams);
