import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import CheckoutService from 'services/checkout/checkout.service';

export const getCheckoutItems = createAsyncThunk('getCheckoutItems', async ({}, thunkAPI) => {
	const { rejectWithValue, fulfillWithValue, signal, getState } = thunkAPI;
	const source = axios.CancelToken.source();
	signal.addEventListener('abort', () => {
		source.cancel();
	});
	const loggedUser = getState().loggedUser.user;

	try {
		const { data } = await CheckoutService.instance.getCheckoutItems(loggedUser, source.token);
		const {
			data: { items, redirect_to: pendingSignaturesUrl, message, pending_signatures: hasPendingSignatures },
		} = data;
		return fulfillWithValue({ items, pendingSignaturesUrl, message, hasPendingSignatures });
	} catch (error) {
		return rejectWithValue({ error });
	}
});
