/* eslint-disable camelcase */
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { Navigation } from '@Backlot-Cars/archie';
import { notifyError } from 'helpers/errorNotifier';
import { formatMessage } from 'helpers/notification/formatMessage';

import usePusher from 'hooks/usePusher';

import ActivitiesService from 'services/navigation/activities.service';
import UserSessionService from 'services/navigation/userSession.service';

const NavigationWrapper = ({ children, user }) => {
	const {
		notifications: activities,
		navigation,
		is_account_master,
		auth,
		on_behalf_of,
		dealership_name,
		normal_image,
		title_clerk_token,
		number_of_pending_checkout_items,
		user_id,
		closed_marketplace,
		unread_activities,
	} = user;

	const userData = {
		id: user_id,
		navigation,
		is_account_master,
		dealership_name,
		normal_image,
		title_clerk_token,
		on_behalf_of,
	};

	const renderNav = Boolean(auth);

	const [notifications, setNotifications] = useState();
	const [unreadNotifications, setUnreadNotifications] = useState(unread_activities);
	const [loadingNotifications, setLoadingNotifications] = useState(false);

	const [loadingGroupAccounts, setLoadingGroupAccounts] = useState(false);
	const [groupAccounts, setGroupAccounts] = useState();

	const router = useRouter();

	usePusher(activities?.channel, activities?.events, (notification) => addNotification(notification), notifications);

	useEffect(() => {
		if (notifications) {
			const unread = notifications.filter((notification) => !notification.read);
			setUnreadNotifications(unread.length);
		}
	}, [notifications]);

	if (!renderNav) return children;

	const handleSetNotifications = async () => {
		setLoadingNotifications(true);
		try {
			const { data } = await ActivitiesService.instance.getActivities(user);
			const activities = data.data?.activities.map(buildNotification);
			setNotifications(activities);
		} catch ({ error }) {
			notifyError(`Error getting user activities - ${error?.message}`);
		}
		setLoadingNotifications(false);
	};

	const handleSetGroupAccounts = async () => {
		setLoadingGroupAccounts(true);
		try {
			const { data } = await UserSessionService.instance.getGroupAccounts(user);
			const accounts = data?.data;
			setGroupAccounts(accounts);
		} catch ({ error }) {
			notifyError(`Error getting group accounts - ${error?.message}`);
		}
		setLoadingGroupAccounts(false);
	};

	const handleDeleteNotification = async (notificationId) => {
		try {
			const { data } = await ActivitiesService.instance.deleteActivity(user, notificationId);
			if (data?.success) {
				setNotifications((prevNotifications) =>
					prevNotifications.filter((notification) => notification.activity !== notificationId),
				);
			}
		} catch ({ error }) {
			notifyError(`Error deleting user activity ${notificationId} - ${error?.message}`);
		}
	};

	const handleDeleteAllNotifications = async () => {
		if (notifications?.length) {
			try {
				const { data } = await ActivitiesService.instance.deleteAllActivities(user);
				if (data?.success) {
					setNotifications([]);
				}
			} catch ({ error }) {
				notifyError(`Error deleting all user activities - ${error?.message}`);
			}
		}
	};

	const handleReadAllNotifications = async () => {
		try {
			ActivitiesService.instance.readAllActivities(user);
		} catch ({ error }) {
			notifyError(`Error on read all notifications - ${error?.message}`);
		}
	};

	const handleClearUnreadNotifications = () => {
		if (unreadNotifications > 0 && notifications) {
			const readNotifications = notifications.map((notification) => ({ ...notification, read: true }));
			setNotifications(readNotifications);
		}
	};

	const addNotification = (notification) => {
		if (notifications) {
			let newNotifications = [...notifications];
			newNotifications.unshift(buildNotification(notification));
			setNotifications(newNotifications);
		} else {
			setUnreadNotifications(unreadNotifications + 1);
		}
	};

	const buildNotification = (notification) => ({
		message: formatMessage(notification?.message),
		activity: notification?.activity,
		options: notification?.options,
		read: notification?.read,
	});

	const handleLogout = async () => {
		try {
			await UserSessionService.instance.logout(user);
			window.location.href = '/sign_in';
		} catch ({ error }) {
			notifyError(`Error to log out user - ${error?.message}`);
		}
	};

	const handleChangeAccount = async (id) => {
		try {
			const path = await UserSessionService.instance.changeAccount(id, user);
			router.push(path);
		} catch ({ error }) {
			notifyError(`Error changing account - ${error?.message}`);
		}
	};

	const handleNavigate = (path, target) => {
		if (target) {
			return window.open(path, target);
		}
		window.location.href = path;
	};

	return (
		<Navigation
			navigation={navigation}
			notifications={notifications}
			pendingCheckoutItems={number_of_pending_checkout_items}
			loadingGroupAccounts={loadingGroupAccounts}
			loadingNotifications={loadingNotifications}
			user={userData}
			unreadNotifications={unreadNotifications}
			groupAccounts={groupAccounts}
			closedMarketplace={closed_marketplace}
			handleDeleteNotification={handleDeleteNotification}
			handleDeleteAllNotifications={handleDeleteAllNotifications}
			handleReadAllNotifications={handleReadAllNotifications}
			handleClearUnreadNotifications={handleClearUnreadNotifications}
			handleNavigate={handleNavigate}
			handleLogout={handleLogout}
			handleSetNotifications={handleSetNotifications}
			handleSetGroupAccounts={handleSetGroupAccounts}
			handleChangeAccount={handleChangeAccount}
		>
			{children}
		</Navigation>
	);
};

export default NavigationWrapper;
