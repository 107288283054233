import { OpenLaneLogo } from '@Backlot-Cars/archie';
import { TopBar } from './styles';
import { EXTERNAL_LANDING_URL } from 'helpers/externalRoutes';

const TopBarLogo = ({ navigateToLanding }) => (
	<TopBar>
		<a href={navigateToLanding ? EXTERNAL_LANDING_URL : process.env.BASE_URL}>
			<OpenLaneLogo height={36} width={276} />
		</a>
	</TopBar>
);

export default TopBarLogo;
