import { createSlice } from '@reduxjs/toolkit';

const initialModalState = {
	isOpen: false,
	type: null,
	content: null,
	confirmCopy: null,
	withAgreement: false,
	onConfirmation: null,
	onClose: null,
};

export const initialState = {
	SELL_ALL_THE_WAY: false,
	EDIT_RESERVE: false,
	AUTOCHECK: false,
	SELL_VEHICLE: false,
	COUNTER_OFFER: false,
	VEHICLE_DETAILS: false,
	EDIT_STARTING_BID: false,
	modal: initialModalState,
	selectedBid: null,
	bidIndex: null,
	data: null,
};

export const vehicleCardModalsSlice = createSlice({
	name: 'vehicleCardModals',
	initialState,
	reducers: {
		toggleSellAllTheWayModal: (state, { payload }) => {
			state.selectedBid = payload?.selectedBid;
			state.SELL_ALL_THE_WAY = !state.SELL_ALL_THE_WAY;
		},
		toggleEditReserveModal: (state, { payload }) => {
			state.selectedBid = payload?.selectedBid;
			state.bidIndex = payload?.bidIndex;
			state.EDIT_RESERVE = !state.EDIT_RESERVE;
		},
		toggleAutocheckModal: (state, { payload }) => {
			state.selectedBid = payload?.selectedBid;
			state.AUTOCHECK = !state.AUTOCHECK;
		},
		toggleSellVehicleModal: (state, { payload }) => {
			state.selectedBid = payload?.selectedBid;
			state.SELL_VEHICLE = !state.SELL_VEHICLE;
		},
		toggleCounterOfferModal: (state, { payload }) => {
			state.selectedBid = payload?.selectedBid;
			state.COUNTER_OFFER = !state.COUNTER_OFFER;
		},
		toggleVehicleDetailsModal: (state, { payload }) => {
			state.selectedBid = payload?.selectedBid;
			state.bidIndex = payload?.bidIndex;
			state.VEHICLE_DETAILS = !state.VEHICLE_DETAILS;
		},
		toggleEditStartingBidModal: (state, { payload }) => {
			state.selectedBid = payload?.selectedBid;
			state.bidIndex = payload?.bidIndex;
			if (payload?.startingBid) {
				state.startingBid = payload?.startingBid;
			}
			state.EDIT_STARTING_BID = !state.EDIT_STARTING_BID;
		},
		openModal: (state, { payload }) => {
			state.selectedBid = payload?.selectedBid;
			state.bidIndex = payload?.bidIndex;
			state.data = payload?.data;
			state.modal = {
				isOpen: !state.modal.isOpen,
				type: payload?.type,
				content: payload?.content,
				confirmCopy: payload?.confirmCopy,
				withAgreement: payload?.withAgreement,
				onConfirmation: payload?.onConfirmation,
				onClose: payload?.onClose,
			};
		},
		closeModal: (state) => {
			state.modal = initialModalState;
		},
		removeModalData: (state) => {
			state.data = null;
		},
		updateModalData: (state, { payload }) => {
			state.data = payload?.data;
		},
	},
});

export const {
	toggleSellAllTheWayModal,
	toggleEditReserveModal,
	toggleAutocheckModal,
	toggleSellVehicleModal,
	toggleCounterOfferModal,
	toggleVehicleDetailsModal,
	toggleEditStartingBidModal,
	openModal,
	closeModal,
	removeModalData,
	updateModalData,
} = vehicleCardModalsSlice.actions;

export default vehicleCardModalsSlice.reducer;
