import { Background } from './styles';
import SvgBackground from './SvgBackground';

const BackgroundCircles = ({ children }) => (
	<Background>
		<SvgBackground />
		{children}
	</Background>
);

export default BackgroundCircles;
