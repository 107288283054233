import { authenticatedRestClient } from 'helpers/restclient';
import { getAuthHeaders, getAuthHeadersFromRedux } from 'helpers/restclient/auth';
import { buildParamsURL, getListContextFromSearchListKey, getSourceTabFromSearchListKey } from 'helpers/search';

export default class BidSalesService {
	static instance = new BidSalesService();

	_endpoints = {
		bidUrl: '/api/timed_sales_offers',
		updateAutobidUrl: '/api/timed_sales_offers/update_autobid_amount',
		removeAutobidUrl: (maxBid, focussedTab) => `/api/auctions/autobids/${maxBid}/cancel?source_tab=${focussedTab}`,
		acceptOfferUrl: `/api/timed_sales_offers/accept`,
		timedSales: `/api/timed_sales?`,
		addToWatchlist: `/api/watched_vehicles`,
		watchlistBidSalesIds: `/api/watched_vehicles/bid_sales`,
		getBidHistory: (bidSaleId) => `/api/bid_sales/${bidSaleId}/bid_history`,
		getCounterOfferHistory: (bidSaleId) => `/api/bid_sales/${bidSaleId}/bid_history/counter_offers`,
		hideVehicle: `/api/hidden_vehicles`,
		unHideVehicle: (vehicleId) => `/api/hidden_vehicles/${vehicleId}`,
		offerRefresh: `/api/timed_sales/offer_refresh/query`,
		getMarketData: (vehicleId) => `/api/market_data/${vehicleId}`,
		getMasterUsers: `/api/subordinates/users`,
	};

	constructor() {
		this.restClient = authenticatedRestClient;
	}

	bid = (data, loggedUser, masterOnBehalfOf) => {
		const endpoint = this._endpoints.bidUrl;
		return this.restClient.post(endpoint, data, getAuthHeaders(loggedUser, masterOnBehalfOf));
	};

	updateAutobid = (data, loggedUser, masterOnBehalfOf) => {
		const endpoint = this._endpoints.updateAutobidUrl;
		return this.restClient.post(endpoint, data, getAuthHeaders(loggedUser, masterOnBehalfOf));
	};

	removeAutobid = (maxBid, focussedTab, masterOnBehalfOf) => {
		const endpoint = this._endpoints.removeAutobidUrl(maxBid, focussedTab);
		return this.restClient.post(endpoint, {}, getAuthHeadersFromRedux(masterOnBehalfOf));
	};

	// eslint-disable-next-line camelcase
	acceptOffer = (vehicleId, offerActivityId, amount, sourceTab, loggedUser, masterOnBehalfOf) => {
		const endpoint = this._endpoints.acceptOfferUrl;
		return this.restClient.post(
			endpoint,
			{
				amount,
				vehicle_id: vehicleId,
				offer_activity_id: offerActivityId,
				source_tab: sourceTab,
			},
			getAuthHeaders(loggedUser, masterOnBehalfOf),
		);
	};

	getVehicles = (loggedUser, params, cancelToken) => {
		const queryParams = `${buildParamsURL({ ...params })}`;
		const endpoint = this._endpoints.timedSales;
		return this.restClient.get(`${endpoint}${queryParams}`, null, getAuthHeaders(loggedUser), cancelToken);
	};

	getWatchlistPage = (page, loggedUser, params) => {
		const queryParams = `${buildParamsURL({ ...params, source_tab: 'watchlist', page })}`;
		const endpoint = this._endpoints.timedSales;
		return this.restClient.get(`${endpoint}${queryParams}`, null, getAuthHeaders(loggedUser));
	};

	addToWatchlist = (vehicleId, sourceTab, vdpType) => {
		const endpoint = this._endpoints.addToWatchlist;
		return this.restClient.post(
			endpoint,
			{
				source_tab: sourceTab,
				watched_vehicle: {
					vehicle_id: vehicleId,
				},
				vdp_type: vdpType,
			},
			getAuthHeadersFromRedux(),
		);
	};

	removeFromWatchlist = (vehicleId, sourceTab, vdpType) => {
		const endpoint = `${this._endpoints.addToWatchlist}/${vehicleId}`;
		return this.restClient.delete(endpoint, getAuthHeadersFromRedux(), {
			source_tab: sourceTab,
			vdp_type: vdpType,
		});
	};

	getWatchlistBidSaleIds = () => {
		const endpoint = this._endpoints.watchlistBidSalesIds;
		return this.restClient.get(endpoint, {}, getAuthHeadersFromRedux());
	};

	getBidHistory = (bidSaleId, page, cancelToken, loggedUser) => {
		const endpoint = this._endpoints.getBidHistory(bidSaleId);
		return this.restClient.get(
			endpoint,
			{ page, observer_id: loggedUser.user_id },
			getAuthHeaders(loggedUser),
			cancelToken,
		);
	};

	getCounterOfferHistory = (bidSaleId, loggedUser, masterOnBehalfOf) => {
		const endpoint = this._endpoints.getCounterOfferHistory(bidSaleId);
		return this.restClient.get(
			endpoint,
			{ observer_id: loggedUser.user_id },
			getAuthHeaders(loggedUser, masterOnBehalfOf),
		);
	};

	hideVehicle = (vehicleId, loggedUser, vdpType, sourceTab) => {
		const endpoint = this._endpoints.hideVehicle;
		return this.restClient.post(
			endpoint,
			{ vehicle_id: vehicleId, vdp_type: vdpType, source_tab: sourceTab },
			getAuthHeaders(loggedUser),
		);
	};

	unHideVehicle = (vehicleId, loggedUser, vdpType, sourceTab) => {
		const endpoint = this._endpoints.unHideVehicle(vehicleId);
		return this.restClient.delete(endpoint, getAuthHeaders(loggedUser), {
			vehicle_id: vehicleId,
			vdp_type: vdpType,
			source_tab: sourceTab,
		});
	};

	offerRefresh = ({ vehicles, loggedUser, focussedSearchListKey, isVdp, reason, includeSummary = false }) => {
		const endpoint = `${this._endpoints.offerRefresh}?reason=${reason}&include_summary=${Boolean(includeSummary)}`;
		const offerRefreshData = {
			context: isVdp ? 'vehicle_detail' : getListContextFromSearchListKey(focussedSearchListKey),
			source_tab: getSourceTabFromSearchListKey(focussedSearchListKey),
			bid_sales: vehicles.map(({ bid_sale_id, version }) => ({ id: bid_sale_id, version })),
		};
		return this.restClient.post(endpoint, offerRefreshData, getAuthHeaders(loggedUser));
	};

	getMarketData = (vehicleId, loggedUser) => {
		const endpoint = `${this._endpoints.getMarketData(vehicleId)}`;
		return this.restClient.get(endpoint, {}, getAuthHeaders(loggedUser));
	};

	getMasterUsers = (vehicleId, loggedUser) => {
		const endpoint = this._endpoints.getMasterUsers;
		return this.restClient.get(endpoint, { vehicle_id: vehicleId }, getAuthHeaders(loggedUser));
	};
}
