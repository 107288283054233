import STATUS_CODES from 'constants/statusCodes';
import OpenlaneError from 'partials/error/openlane';

const InternalServerError = () => {
	const title = 'Internal server error';
	const description =
		'Sorry, it looks like something went wrong. We’re aware of the problem and working to fix it. Please wait a few minutes and try again.';

	return <OpenlaneError statusCode={STATUS_CODES.INTERNAL_SERVER_ERROR} title={title} description={description} />;
};

export default InternalServerError;
