export const apiV3Headers = { Accept: 'application/json, application/vnd.backlotcars.v3' };

export const getHeaderFromCtx = (ctx, headerName) => ctx?.req?.headers?.[headerName];

export const X_REAL_IP_HEADER_NAME = 'x-real-ip'; // Client IP injected by reblaze on each request
export const X_CLIENT_IP_HEADER_NAME = 'x-client-ip'; // Client IP stored in the tokens table
export const USER_AGENT_HEADER_NAME = 'user-agent';

export const getClientHeaders = (ctx) => {
	const xClientRealIP = getHeaderFromCtx(ctx, X_REAL_IP_HEADER_NAME);
	const userAgent = getHeaderFromCtx(ctx, USER_AGENT_HEADER_NAME);
	return {
		[X_CLIENT_IP_HEADER_NAME]: xClientRealIP,
		[USER_AGENT_HEADER_NAME]: userAgent,
	};
};
