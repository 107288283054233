import { checkoutItemsAdapter } from '../..';

export const getCheckoutItemsFulfilledAction = (state, { payload }) => {
	const { items, pendingSignaturesUrl, message, hasPendingSignatures } = payload || {};
	checkoutItemsAdapter.upsertMany(state, items);
	state.items = items.map(({ id }) => id).sort();
	state.pendingSignaturesUrl = pendingSignaturesUrl;
	state.message = message;
	state.hasPendingSignatures = hasPendingSignatures;
	state.savedForLaterItems = [];
	state.errorMessage = '';
	state.success = true;
	state.isLoading = false;
};
