import urlsContext from 'contexts/urls/urlsContext';

const urlsProvider = (WrappedComponent) => {
	const Wrapper = props => {
		return (
			<urlsContext.Provider value={{ ...props.urls }}>
				<WrappedComponent {...props} />
			</urlsContext.Provider>
		);
	};

	return Wrapper;
};

export default urlsProvider;
