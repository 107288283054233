import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const vehicleOfferStatusSelector = createSelector(
	({ vehicleDetailPage }) => vehicleDetailPage,
	(vehicleDetailPage) => vehicleDetailPage.offerStatus,
);

export const vdpAsModalSelector = createSelector(
	({ vehicleDetailPage }) => vehicleDetailPage,
	(vehicleDetailPage) => vehicleDetailPage.openAsModal,
);

export const useVehicleOfferStatus = () => useSelector(vehicleOfferStatusSelector);
export const useVDPAsModal = () => useSelector(vdpAsModalSelector);
