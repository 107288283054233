import dayjs from 'dayjs';

export const requestStartTime = (config) => {
	config.metadata = {
		requestStartTime: dayjs().valueOf(),
	};

	return config;
};

export const requestDuration = (response) => {
	const start = response.config.metadata.requestStartTime;
	const end = dayjs().valueOf();

	response.headers['X-Request-Duration'] = end - start;
	return response;
};
