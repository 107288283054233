import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
	gtvOffers: null,
	loading: false,
	metadata: {
		page: null,
	},
	filters: {
		search: '',
		showAll: false,
		orderBy: null,
		direction: null,
	},
};

export const gtvOfferIndexSlice = createSlice({
	name: 'gtvOfferIndex',
	initialState,
	reducers: {
		setGtvOffers: (state, { payload }) => {
			state.gtvOffers = payload.gtvOffers;
			state.metadata = payload.metadata;
		},
		setFilters: (state, { payload }) => {
			state.filters = payload.filters;
		},
		setLoading: (state, { payload }) => {
			state.loading = payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, { payload }) => {
			const payloadData = payload?.gtvOfferIndex;
			return {
				...state,
				gtvOffers: payloadData?.gtvOffers,
				metadata: payloadData?.metadata,
			};
		});
	},
});

export const { setGtvOffers, setFilters, setLoading } = gtvOfferIndexSlice.actions;

export default gtvOfferIndexSlice.reducer;
