import { isNewJWTAuthenticationEnabled } from '../auth';
import refreshJWT from './refreshJWT';
import refreshJWTWithRefreshToken from './refreshJWTWithRefreshToken';

export const addAuthInterceptor = (config, restClientInstance) => {
	if (isNewJWTAuthenticationEnabled()) {
		if (config.sendAuthHeaders) {
			restClientInstance._axios.interceptors.request.use((config) =>
				refreshJWTWithRefreshToken(config, restClientInstance),
			);
		}
	} else {
		restClientInstance._axios.interceptors.request.use((config) => refreshJWT(config, restClientInstance));
	}
};
