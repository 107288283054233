import PropTypes from 'prop-types';

const brand = 'OPENLANE';

const BlcMetaType = {
	/**
	 * Lets you customize the sites favicon
	 */
	favicon: PropTypes.string,
	/**
	 * Set the site title
	 */
	title: PropTypes.string,
	/**
	 * Set the site description
	 */
	description: PropTypes.string,
	/**
	 * Set the site keywords
	 */
	keywords: PropTypes.string,
	/**
	 * Set the site Author
	 */
	author: PropTypes.string,
	/**
	 * Set the site Image URL for Facebook or Twitter
	 */
	imageURL: PropTypes.string,
	/**
	 * Article for Facebook share
	 */
	article: PropTypes.string,
	/**
	 * Determines if the site is mobile friendly on older browsers (leave it true unless purely necessary)
	 */
	handheld: PropTypes.bool,
	/**
	 * Add a robots meta tag
	 */
	robotsMeta: PropTypes.bool
};

export const BlcMetaDefaultProps = {
	favicon: `/images/logos/${brand}/favicon-16x16.png`,
	title: brand,
	description: 'Making Wholesale Easy',
	keywords: `${brand}, Wholesale, Cars`,
	author: brand,
	imageURL: `/images/logos/${brand}/favicon-96x96.png`,
	article: 'article',
	handheld: true,
	robotsMeta: false
};

export const BlcMetaTypeProps = () => <></>;

BlcMetaTypeProps.defaultProps = BlcMetaDefaultProps;

BlcMetaTypeProps.propTypes = BlcMetaType;

export default BlcMetaType;
