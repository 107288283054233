// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';

const expired = jwt => {
	const decoded = jwt_decode(jwt);
	const now = new Date().getTime() / 1000;
	const expiration = decoded.exp - 60;

	if (now > expiration) {
		return true;
	}

	return false;
};

export default expired;
