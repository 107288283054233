import { colors } from '@Backlot-Cars/archie';
import styled from 'styled-components';
import { media } from 'public/styles';

export const Container = styled.div`
	display: flex;
	justify-content: center;
	padding: 60px;

	@media ${media.smaller} {
		padding: 10px;
	}
`;

export const ContentContainer = styled.div`
	background-color: ${colors.white};
	border-radius: 16px;
	padding: 40px;
	width: 640px;
	height: 496px;
	display: flex;
	align-items: center;
	flex-direction: column;
	z-index: 2;
	box-shadow: 0px 4px 24px 0px #0000001a;

	@media ${media.smaller} {
		padding: 10px;
		width: auto;
	}
`;

export const Content = styled.div`
	max-width: 432px;
	display: flex;
	align-items: center;
	flex-direction: column;

	@media ${media.smaller} {
		svg {
			width: 300px;
		}
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 24px;
`;
