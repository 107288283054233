import { startingBidBubble } from '../bubbles';

const getFailedOfferStatus = () => {
	return {
		...startingBidBubble,
		ended: true,
	};
};

export default getFailedOfferStatus;
