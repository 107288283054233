/* eslint-disable camelcase */
import STATUS_CODES from 'constants/statusCodes';
import { getSignInUrl } from 'helpers/externalRoutes';
import buildUser from 'helpers/user/buildUser';
import { clearUser, setLoggedUserFields } from 'store/slices/loggedUsers/loggedUserSlice';
import { getDispatcher } from 'store/store';
import { withCookiesRestClient } from '..';
import expired from '../../jwt/expired';
import { ACCESS_TOKEN, getClientSideCookie } from '../cookies';

const refreshJWT = async (config) => {
	let access_token = getClientSideCookie(ACCESS_TOKEN);

	if (!access_token || expired(access_token)) {
		const dispatch = getDispatcher();

		try {
			const res = await withCookiesRestClient.post(
				'/api/users/sessions/refresh',
				{},
				{
					Accept: 'application/json, application/vnd.backlotcars.v3',
					'Content-Type': 'application/json',
				},
			);
			access_token = res.data.access_token;
			if (res.status == STATUS_CODES.OK) {
				const user = await buildUser(access_token);
				dispatch && dispatch(setLoggedUserFields({ loggedUser: user }));
			} else {
				dispatch && dispatch(clearUser());
				const signInRedirection = getSignInUrl(window?.location?.href);
				window.location.assign(signInRedirection);
			}
		} catch (error) {
			dispatch && dispatch(clearUser());
			const signInRedirection = getSignInUrl(window?.location?.href);
			window.location.assign(signInRedirection);
		}
	}

	config.headers['Authorization'] = `Token token=${access_token}`;
	config.headers['X-User-Jwt'] = null;

	return config;
};

export default refreshJWT;
