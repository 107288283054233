import { createSlice } from '@reduxjs/toolkit';

import { safeAtob } from 'helpers/timedSales/safeAtob';
import { BID_SALE_AUCTION_STATUS } from 'constants/bidSale';
import dayjs from 'dayjs';

export const initialState = {
	started: false,
	activeAuctionId: null,
	pickupAddresses: null,
	vehicleStatus: null,
	vehicleStatusLabels: null,
	actives: {
		bidSales: null,
		metadata: null,
		filters: {
			search: '',
			sellerId: '',
		},
		loading: false,
		success: undefined,
		submitted: false,
	},
	inactives: {
		bidSales: null,
		metadata: null,
		filters: {
			search: '',
			showHidden: false,
			sellerId: '',
		},
		loading: false,
		success: undefined,
		submitted: false,
		onHold: [],
	},
};

const decodeOfferData = (offerData) => {
	if (offerData) {
		return {
			activity_id: safeAtob(offerData?.best_offer_activity_id),
			amount: safeAtob(offerData?.best_offer_amount),
			city: safeAtob(offerData?.best_offer_city),
			date: safeAtob(offerData?.best_offer_date),
			dealership_name: safeAtob(offerData?.best_offer_dealership_name),
			id: safeAtob(offerData?.best_offer_id),
			offerer_id: safeAtob(offerData?.best_offer_offerer_id),
			state: safeAtob(offerData?.best_offer_state),
		};
	}
	return null;
};

export const getObjectKey = (status) => (status == 'inactive' ? 'inactives' : 'actives');

export const sellerBidSalesSlice = createSlice({
	name: 'sellerBidSales',
	initialState,
	reducers: {
		startUpdateAll: (state, { payload }) => {
			const objKey = getObjectKey(payload?.status);
			state[objKey].loading = true;
			state[objKey].success = undefined;
		},
		updateAllFail: (state, { payload }) => {
			const objKey = getObjectKey(payload?.status);
			state[objKey].loading = false;
			state[objKey].success = false;
		},
		updateAll: (state, { payload }) => {
			const { bidSales, metadata } = payload;
			const objKey = getObjectKey(payload?.status);
			state[objKey] = {
				...state[objKey],
				// add this defensiveness behavior since we are getting nullable values on array
				bidSales: (bidSales || []).filter((bidSale) => bidSale),
				metadata: metadata,
				loading: false,
				success: true,
			};
		},
		updateOne: (state, { payload }) => {
			const { index, bidSale } = payload;
			state[getObjectKey(payload?.status)].bidSales[index] = bidSale;
		},
		deleteOne: (state, { payload }) => {
			const { id } = payload;
			const objKey = getObjectKey(payload?.status);
			state[objKey].bidSales = state[objKey].bidSales.filter((bid) => bid.bid_sale?.id != id);
			state[objKey].metadata = {
				...state[objKey].metadata,
				total_count: state[objKey].metadata.total_count - 1,
			};
		},
		updateStarted: (state, { payload }) => {
			const { data } = payload;
			if (data?.started) {
				state.started = data.started;
			}

			if (data?.auction_id) {
				state.activeAuctionId = data.auction_id;
			}
		},
		updateAuctionToLive: (state) => {
			if (state.actives.bidSales) {
				state.actives.bidSales = state.actives.bidSales.map((bidSale) =>
					(!bidSale.bid_sale.auction_status ||
						bidSale.bid_sale.auction_status === BID_SALE_AUCTION_STATUS.pending) &&
					dayjs(bidSale.bid_sale.auction_ends_at).isSame(dayjs(), 'day') &&
					bidSale.bid_sale.auction_id === state.activeAuctionId
						? {
							...bidSale,
							bid_sale: {
								...bidSale.bid_sale,
								auction_status: BID_SALE_AUCTION_STATUS.live,
								top_offer: decodeOfferData(bidSale?.bid_sale?.top_offer),
							},
						  }
						: bidSale,
				);
			}
		},
		updateSearch: (state, { payload }) => {
			const { value } = payload;
			const objKey = getObjectKey(payload?.status);
			state[objKey].filters.search = value;
		},
		updateFilterBySellerId: (state, { payload }) => {
			const { sellerId } = payload;
			const objKey = getObjectKey(payload?.status);
			state[objKey].filters.sellerId = sellerId;
		},
		updateShowHidden: (state, { payload }) => {
			const { value } = payload;
			state.inactives.filters.showHidden = value;
		},
		updateSubmitted: (state, { payload }) => {
			const { submitted } = payload;
			const objKey = getObjectKey(payload?.status);
			state[objKey].submitted = submitted;
		},
		// Add vehicle to recent vehicle on_hold list
		addToOnHold: (state, { payload }) => {
			const { vehicleId } = payload;
			state.inactives.onHold.push(vehicleId);
		},
		// Remove vehicle from recent vehicle on_hold list
		removeFromOnHold: (state, { payload }) => {
			const { vehicleId } = payload;
			state.inactives.onHold = state.inactives.onHold.filter((id) => id != vehicleId);
		},
		updatePickupAddresses: (state, { payload }) => {
			const { addresses } = payload;
			state.pickupAddresses = addresses;
		},
		updateVehicleStatus: (state, { payload }) => {
			const { status } = payload;
			state.vehicleStatus = status;
		},
		updateVehicleStatusLabels: (state, { payload }) => {
			const { labels } = payload;
			state.vehicleStatusLabels = labels;
		},
	},
});

export const {
	startUpdateAll,
	updateAll,
	updateAllFail,
	updateOne,
	deleteOne,
	updateStarted,
	updateAuctionToLive,
	updateSearch,
	updateShowHidden,
	updateSubmitted,
	addToOnHold,
	removeFromOnHold,
	updateFilterBySellerId,
	updatePickupAddresses,
	updateVehicleStatus,
	updateVehicleStatusLabels,
} = sellerBidSalesSlice.actions;

export default sellerBidSalesSlice.reducer;
