import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const initialState = {
	auctionsStatus: {
		closest: {
			started: false,
			id: '',
		},
		// Check whether there are vehicles in overtime or not
		overtime: false,
	},
};

export const buyerTimedSaleSlice = createSlice({
	name: 'buyerTimedSale',
	initialState,
	reducers: {
		auctionStart: (state) => {
			state.auctionsStatus.closest.started = true;
		},
		setAuctionsStatus: (state, { payload }) => {
			if (payload.closest) {
				state.auctionsStatus = {
					...state.auctionsStatus,
					...payload,
					// we are getting a date from BE but we don't need it at this point
					date: undefined,
				};
			}
		},
		setOvertime: (state, { payload }) => {
			state.auctionsStatus.overtime = payload?.overtime;
			state.auctionsStatus.closest.started = false;
		},
	},
});

export const { auctionStart, setAuctionsStatus, setOvertime } = buyerTimedSaleSlice.actions;

export default buyerTimedSaleSlice.reducer;
