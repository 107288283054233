import BlcLayoutBase from 'components/BlcLayout/BlcLayout.base.js';
import BackgroundCircles from './BackgroundCircles';
import TopBarLogo from './TopBarLogo';
import { PublicLayoutWrapper } from './styles';
import { loggedUSerSelector } from 'store/slices/loggedUsers/selector';
import { getState } from 'store/store';

const PublicLayout = (props) => {
	const user = loggedUSerSelector(getState());
	return (
		<BlcLayoutBase {...props?.meta}>
			<PublicLayoutWrapper>
				{!user?.id && <TopBarLogo navigateToLanding={props.isPublicPage} />}
				<BackgroundCircles>{props.children}</BackgroundCircles>
			</PublicLayoutWrapper>
		</BlcLayoutBase>
	);
};

export default PublicLayout;
