import { toast } from '@Backlot-Cars/archie';
import { createSlice } from '@reduxjs/toolkit';
import { BID_SALE_AUCTION_STATUS, BID_SALE_SOURCE_TAB } from 'constants/bidSale';
import { HYDRATE } from 'next-redux-wrapper';
import { initialState as searchInitialState } from 'reducers/search';
import {
	setOvertimeCompleted as setOvertimeCompletedHelper,
	updateOfferStatus
} from 'store/helpers/vehicleOfferUpdates';
import {
	calculatePages,
	calculatePaginationAfterAddition,
	filterWatchedVehiclesFromNotificationList,
	getListKeysForWatchlistAndHiddenActions,
	insertOrdered,
	insertVehicleIntoIdslist,
	isWatchlistFocussed,
	removeFromVehicleIds,
	removeFromWatchlistVehiclesBidIds,
	updatePagesToHighlight
} from 'store/helpers/watchlist';
import { closeCampaign, loadFeaturedVehicleCampaign } from 'store/slices/buyerVehicles/thunks/featuredVehicles';
import { getWatchlistPage } from 'store/slices/buyerVehicles/thunks/watchlist';
import { loadVehicles, loadVehiclesMarketplace, setVehicleAsHidden, unHideVehicle } from './buyerVehiclesThunks';
import {
	LIST_KEYS,
	addVehicleToListKeyAndUpdateEntities,
	getKeyFromSourceTab,
	getNotSelectedKeys,
	removeVehicleFromListKeyAndUpdateEntities,
	vehiclesAdapter
} from './helpers/vehicles';

export const initialState = vehiclesAdapter.getInitialState({
	...searchInitialState,
	focussedTab: BID_SALE_SOURCE_TAB.auction,
});

export const buyerTimedSaleVehiclesSlice = createSlice({
	name: 'buyerVehicles',
	initialState,
	reducers: {
		startBid: (state, { payload }) => {
			const { id } = payload;
			const entity = state.entities[id];

			const currentAuctionStatus = entity?.bid_sale_auction_status;
			let newAuctionStatus = currentAuctionStatus;
			if (!currentAuctionStatus || currentAuctionStatus === BID_SALE_AUCTION_STATUS.pending) {
				newAuctionStatus = BID_SALE_AUCTION_STATUS.live;
			}

			vehiclesAdapter.updateOne(state, { id, changes: { bid_sale_auction_status: newAuctionStatus } });
		},
		endBid: (state) => {
			Object.values(state.entities).forEach((entity) => {
				const { id, bid_sale_auction_status } = entity;
				// eslint-disable-next-line camelcase
				if (bid_sale_auction_status === 'overtime') {
					vehiclesAdapter.updateOne(state, { id, changes: { auctionEnded: true } });
				}
			});
		},
		updateBid: (state, { payload: { id, changes: encodedChanges, loggedUser } }) => {
			const changes = updateOfferStatus({
				currentOfferState: state.entities[id],
				updatedVehicleId: id,
				updatedOfferState: encodedChanges,
				loggedUser,
			});

			if (changes) vehiclesAdapter.updateOne(state, { id, changes });
		},
		setOvertimeCompleted: (state, { payload: { id } }) => {
			const changes = setOvertimeCompletedHelper({ currentOfferState: state.entities[id] });
			vehiclesAdapter.updateOne(state, {
				id,
				changes,
			});
		},
		removeVehicleFromCurrentPage: (state, { payload }) => {
			const { vehicleId, listKey } = payload;
			removeVehicleFromListKeyAndUpdateEntities({ state, vehicleId, listKey });
		},
		addVehicleToOfferList: (state, { payload: { vehicle, searchListKey } }) => {
			const { totalElements, itemsPerPage, vehicleIds } = state.searchLists[searchListKey];
			state.searchLists[searchListKey].totalElements = totalElements + 1;
			state.searchLists[searchListKey].pageCount = calculatePages(
				state.searchLists[searchListKey].totalElements,
				itemsPerPage,
			);

			if (vehicleIds.length < itemsPerPage) {
				// once tbd_126623_unified_marketplace_response we can delete next line.
				vehiclesAdapter.upsertOne(state, { id: vehicle.id, ...vehicle });
				if (!vehicleIds.includes(vehicle.id)) vehicleIds.push(vehicle.id);
			}
		},
		// modifying vehicle list
		// IMPORTANT CONTEXT: we know that list ir ordered by bid_sale_id desc so we can know where to add or remove elements based on that
		addToWatchlist: (state, { payload }) => {
			const { vehicle } = payload;
			const { entities } = state;
			const currentPage = state.searchLists[LIST_KEYS.watchlist].currentSearch.params.page;
			const { vehicleIds, itemsPerPage } = state.searchLists[LIST_KEYS.watchlist];

			insertOrdered(state.searchLists[LIST_KEYS.watchlist].allWatchlistVehiclesBidIds, vehicle.bid_sale_id);

			const isCurrentOrNextPage =
				!state.searchLists[LIST_KEYS.watchlist].vehicleIds.length ||
				vehicle.bid_sale_id < entities[vehicleIds[0]].bid_sale_id ||
				currentPage === 1;

			if (isCurrentOrNextPage) {
				// if it's next page it will not be added
				insertVehicleIntoIdslist(entities, vehicleIds, vehicle, itemsPerPage);
				state.searchLists[LIST_KEYS.watchlist].pagesToHighlight = updatePagesToHighlight(
					state.searchLists[LIST_KEYS.watchlist].allWatchlistVehiclesBidIds,
					state.searchLists[LIST_KEYS.watchlist].vehiclesBidIdsWithUpdates,
					state.searchLists[LIST_KEYS.watchlist].itemsPerPage,
				);
			}

			state.entities[vehicle.id]
				? (state.entities[vehicle.id].watching = true)
				: (state.entities[vehicle.id] = { ...vehicle, watching: true });

			const { totalElements, pageCount } = calculatePaginationAfterAddition(
				state.searchLists[LIST_KEYS.watchlist].totalElements,
				itemsPerPage,
			);
			state.searchLists[LIST_KEYS.watchlist].totalElements = totalElements;
			state.searchLists[LIST_KEYS.watchlist].pageCount = pageCount;
			if (state.searchLists[LIST_KEYS.watchlist].totalElements === 1) {
				state.searchLists[LIST_KEYS.watchlist].currentSearch.params.page = 1;
			}
		},
		removeFromWatchlist: (state, { payload }) => {
			const { vehicle, doNotModifyCurrentPage, watchlistListKey } = payload;
			const { entities } = state;

			state.searchLists[LIST_KEYS.watchlist].allWatchlistVehiclesBidIds = removeFromWatchlistVehiclesBidIds(
				state.searchLists[LIST_KEYS.watchlist].allWatchlistVehiclesBidIds,
				vehicle.bid_sale_id,
			);

			// remove from list
			if (!doNotModifyCurrentPage) {
				const { vehicleIds } = removeFromVehicleIds({
					vehicleIds: state.searchLists[watchlistListKey].vehicleIds,
					vehicleIdToRemove: vehicle.id,
					totalElements: state.searchLists[watchlistListKey].totalElements,
					itemsPerPage: state.searchLists[watchlistListKey].itemsPerPage,
				});
				state.searchLists[watchlistListKey].vehicleIds = vehicleIds;

				state.searchLists[watchlistListKey].pagesToHighlight = updatePagesToHighlight(
					state.searchLists[watchlistListKey].allWatchlistVehiclesBidIds,
					state.searchLists[watchlistListKey].vehiclesBidIdsWithUpdates,
					state.searchLists[watchlistListKey].itemsPerPage,
				);
			}
			state.searchLists[watchlistListKey].totalElements -= 1;
			state.searchLists[watchlistListKey].pageCount = calculatePages(
				state.searchLists[watchlistListKey].totalElements,
				state.searchLists[watchlistListKey].itemsPerPage,
			);

			if (entities[vehicle.id]) state.entities[vehicle.id].watching = false;
		},
		// for Real time notification updates on watchlist
		fetchWatchlistVehiclesBidIdsSuccess: (state, { payload }) => {
			const { watchlistVehiclesBidIds } = payload;
			state.searchLists[LIST_KEYS.watchlist].allWatchlistVehiclesBidIds = watchlistVehiclesBidIds;
		},
		initFocussedTab: (state, { payload }) => {
			const { focussedTab } = payload;
			state.focussedTab = focussedTab;
		},
		setFocussedTab: (state, { payload }) => {
			const { focussedTab } = payload;
			state.focussedTab = focussedTab;
			// update notification when watchlist is focussed
			if (isWatchlistFocussed(focussedTab)) {
				const watchedVehicles = state.searchLists[LIST_KEYS.watchlist].vehicleIds.map(
					(vehicleId) => state.entities[vehicleId],
				);

				state.searchLists[LIST_KEYS.watchlist].vehiclesBidIdsWithUpdates =
					filterWatchedVehiclesFromNotificationList(
						state.searchLists[LIST_KEYS.watchlist].vehiclesBidIdsWithUpdates,
						watchedVehicles,
					);

				state.searchLists[LIST_KEYS.watchlist].pagesToHighlight = updatePagesToHighlight(
					state.searchLists[LIST_KEYS.watchlist].allWatchlistVehiclesBidIds,
					state.searchLists[LIST_KEYS.watchlist].vehiclesBidIdsWithUpdates,
					state.searchLists[LIST_KEYS.watchlist].itemsPerPage,
				);
			}
		},
		setWatchlistElementNotification: (state, { payload }) => {
			const { bid_sale_id } = payload;
			const watchedVehicles = state.searchLists[LIST_KEYS.watchlist].vehicleIds.map(
				(vehicleId) => state.entities[vehicleId],
			);
			const vehicleIsUpdatedViaWebhooks =
				isWatchlistFocussed(state.focussedTab) &&
				// eslint-disable-next-line camelcase
				watchedVehicles.find((vehicle) => vehicle.bid_sale_id === bid_sale_id);

			if (
				!vehicleIsUpdatedViaWebhooks &&
				state.searchLists[LIST_KEYS.watchlist].allWatchlistVehiclesBidIds.includes(bid_sale_id)
			) {
				state.searchLists[LIST_KEYS.watchlist].vehiclesBidIdsWithUpdates.push(bid_sale_id);
				state.searchLists[LIST_KEYS.watchlist].pagesToHighlight = updatePagesToHighlight(
					state.searchLists[LIST_KEYS.watchlist].allWatchlistVehiclesBidIds,
					state.searchLists[LIST_KEYS.watchlist].vehiclesBidIdsWithUpdates,
					state.searchLists[LIST_KEYS.watchlist].itemsPerPage,
				);
			}
		},
		clearFeaturedVehicleCampaign: (state, { payload }) => {
			const { searchListKey } = payload;
			state.searchLists[searchListKey].featured = initialState.searchLists[searchListKey].featured;
		},
		clearAllVehiclesList: (state) => {
			Object.keys(state.searchLists).forEach((key) => {
				state.searchLists[key].vehicleIds = [];
			});
			state.entities = {};
		},
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			// just merge the data on hydrate
			return {
				...state,
				focussedTab: action.payload.buyerVehicles?.focussedTab,
			};
		});
		builder.addCase(loadVehicles.pending, (state, action) => {
			const key = getKeyFromSourceTab(action.meta.arg.sourceTab);
			state.searchLists[key].isLoading = true;
			state.searchLists[key].success = undefined;
		});
		builder.addCase(loadVehicles.fulfilled, (state, { payload, meta }) => {
			let listKey = getKeyFromSourceTab(meta.arg.sourceTab);
			const {
				data: { vehicles },
				metadata,
			} = payload;

			addVehicleToListKeyAndUpdateEntities({ newVehicleList: vehicles, state, listKey, shouldDecode: true });
			state.searchLists[listKey].pageCount = metadata.page_count;
			state.searchLists[listKey].totalElements = metadata.total_count;
			state.searchLists[listKey].currentSearch.params.page = Number(metadata.page);
			state.searchLists[listKey].itemsPerPage = Number(metadata.per_page);
			state.searchLists[listKey].errorMessage = '';
			state.searchLists[listKey].success = true;
			state.searchLists[listKey].isLoading = false;
			state.searchLists[listKey].isReady = true;
		});
		builder.addCase(loadVehicles.rejected, (state, { meta }) => {
			if (!meta.aborted) {
				const watchedVehicleIds = state.searchLists[LIST_KEYS.watchlist].vehicleIds;
				const listKey = getKeyFromSourceTab(meta.arg.sourceTab);

				const elementsFromFailedList = state.searchLists[listKey].vehicleIds.filter(
					(id) => !watchedVehicleIds.includes(id),
				);
				vehiclesAdapter.removeMany(state, elementsFromFailedList);

				state.searchLists[listKey].vehicleIds = [];
				state.searchLists[listKey].success = false;
				state.searchLists[listKey].isLoading = false;
				state.searchLists[listKey].isReady = true;
				state.searchLists[listKey].errorMessage = 'There was an error while fetching the vehicles.';
			}
		});
		// Hidde vehicle
		builder.addCase(setVehicleAsHidden.pending, (state, { meta }) => {
			state.entities[meta.arg.vehicle.id].hideVehicleRequestIsLoading = true;
		});
		builder.addCase(setVehicleAsHidden.fulfilled, (state, { meta }) => {
			const hiddenVehicle = state.entities[meta.arg.vehicle.id];
			const { searchListKey } = meta.arg;
			const { hiddenListKey } = getListKeysForWatchlistAndHiddenActions(searchListKey);
			hiddenVehicle.hideVehicleRequestIsLoading = false;
			hiddenVehicle.hidden = true;
			hiddenVehicle.watching = false;
			const hiddenSearchList = state.searchLists[hiddenListKey];

			// insert into hidden list
			insertVehicleIntoIdslist(
				state.entities,
				hiddenSearchList.vehicleIds,
				hiddenVehicle,
				hiddenSearchList.itemsPerPage,
			);
			const { totalElements: totalElementsAfterAddition, pageCount: pageCountAfterAddition } =
				calculatePaginationAfterAddition(hiddenSearchList.totalElements, hiddenSearchList.itemsPerPage);
			hiddenSearchList.totalElements = totalElementsAfterAddition;
			hiddenSearchList.pageCount = pageCountAfterAddition;

			// delete from the main source list (marketplace or auction)
			const sourceSearchList = state.searchLists[searchListKey];
			const { vehicleIds, totalElements, pageCount } = removeFromVehicleIds({
				vehicleIds: sourceSearchList.vehicleIds,
				vehicleIdToRemove: meta.arg.vehicle.id,
				totalElements: sourceSearchList.totalElements,
				itemsPerPage: sourceSearchList.itemsPerPage,
				checkPresenceOnVehicleIdsToCalculatePagination: true,
			});
			sourceSearchList.vehicleIds = vehicleIds;
			sourceSearchList.totalElements = totalElements;
			sourceSearchList.pageCount = pageCount;
			toast.success('Vehicle successfully hidden');
		});
		builder.addCase(setVehicleAsHidden.rejected, (state, { meta }) => {
			toast.error('Hide action has failed, please retry');
			state.entities[meta.arg.vehicle.id].hideVehicleRequestIsLoading = false;
		});

		// UnHide vehicle
		builder.addCase(unHideVehicle.pending, (state, { meta }) => {
			state.entities[meta.arg.vehicleToUnHide.id].hideVehicleRequestIsLoading = true;
		});
		builder.addCase(unHideVehicle.fulfilled, (state, { meta }) => {
			const hiddenVehicle = state.entities[meta.arg.vehicleToUnHide.id];
			const { searchListKey } = meta.arg;
			const { hiddenListKey } = getListKeysForWatchlistAndHiddenActions(searchListKey);
			hiddenVehicle.hideVehicleRequestIsLoading = false;
			hiddenVehicle.hidden = false;

			// delete from entities
			const nonSelectedKeys = getNotSelectedKeys(hiddenListKey);
			const otherListIds = [];
			nonSelectedKeys.forEach((nonSelectedKey) => {
				otherListIds.push(...(state.searchLists[nonSelectedKey]?.vehicleIds || []));
			});
			if (!otherListIds.includes(meta.arg.vehicleToUnHide.id)) {
				vehiclesAdapter.removeOne(state, meta.arg.vehicleToUnHide.id);
			}

			// delete from hidden list
			const hiddenSearchList = state.searchLists[hiddenListKey];
			const { vehicleIds, totalElements, pageCount } = removeFromVehicleIds({
				vehicleIds: hiddenSearchList.vehicleIds,
				vehicleIdToRemove: meta.arg.vehicleToUnHide.id,
				totalElements: hiddenSearchList.totalElements,
				itemsPerPage: hiddenSearchList.itemsPerPage,
			});
			hiddenSearchList.vehicleIds = vehicleIds;
			hiddenSearchList.totalElements = totalElements;
			hiddenSearchList.pageCount = pageCount;
			toast.success('Vehicle successfully unhidden');
		});
		builder.addCase(unHideVehicle.rejected, (state, { meta }) => {
			toast.error('Unhide has failed, please retry');
			state.entities[meta.arg.vehicleToUnHide.id].hideVehicleRequestIsLoading = false;
		});
		builder.addCase(loadVehiclesMarketplace.pending, (state, { meta }) => {
			state.searchLists[meta.arg.searchListKey].isLoading = true;
			state.searchLists[meta.arg.searchListKey].errorMessage = '';
		});
		builder.addCase(loadVehiclesMarketplace.fulfilled, (state, { payload, meta }) => {
			const { vehicles, pageCount, totalCount, page, user } = payload;
			const listKey = meta.arg.searchListKey;
			addVehicleToListKeyAndUpdateEntities({ newVehicleList: vehicles, state, listKey, shouldDecode: false });
			state.searchLists[listKey].isLoading = false;
			state.searchLists[listKey].isReady = true;
			state.searchLists[listKey].pageCount = pageCount;
			state.searchLists[listKey].totalElements = totalCount;
			state.searchLists[listKey].currentSearch.params.page = page;
			state.user = user;
		});
		builder.addCase(loadVehiclesMarketplace.rejected, (state, { meta, payload }) => {
			if (!meta.aborted) {
				const listKey = meta.arg.searchListKey;
				// clear list
				addVehicleToListKeyAndUpdateEntities({ newVehicleList: [], state, listKey, shouldDecode: false });
				state.searchLists[listKey].isLoading = false;
				state.searchLists[listKey].isReady = true;
				state.searchLists[listKey].errorMessage = payload?.errorMessage;
			}
		});
		// Featured
		builder.addCase(loadFeaturedVehicleCampaign.pending, (state, { meta }) => {
			state.searchLists[meta.arg.searchListKey].featured.isLoading = true;
			state.searchLists[meta.arg.searchListKey].featured.errorMessage = '';
		});
		builder.addCase(loadFeaturedVehicleCampaign.fulfilled, (state, { payload, meta }) => {
			const { vehicles, id, searchFilter, seeMoreText, title } = payload;
			const listKey = meta.arg.searchListKey;
			state.searchLists[listKey].featured.isLoading = false;
			state.searchLists[listKey].featured.showFeaturedVehicles = true;
			state.searchLists[listKey].featured.id = id;
			state.searchLists[listKey].featured.searchFilter = searchFilter;
			state.searchLists[listKey].featured.seeMoreText = seeMoreText;
			state.searchLists[listKey].featured.title = title;
			addVehicleToListKeyAndUpdateEntities({
				state,
				newVehicleList: vehicles,
				listKey,
				isFeaturedVehicleList: true,
			});
		});
		builder.addCase(loadFeaturedVehicleCampaign.rejected, (state, { meta, error }) => {
			state.searchLists[meta.arg.searchListKey].featured.isLoading = false;
			state.searchLists[meta.arg.searchListKey].featured.errorMessage =
				error?.message || 'Error getting featured vehicles';
		});
		builder.addCase(closeCampaign.fulfilled, (state, { meta }) => {
			const listKey = meta.arg.searchListKey;
			state.searchLists[listKey].featured.showFeaturedVehicles = false;
		});
		builder.addCase(getWatchlistPage.pending, (state) => {
			state.searchLists[LIST_KEYS.watchlist].isLoading = true;
		});
		builder.addCase(getWatchlistPage.fulfilled, (state, { payload }) => {
			const { vehicles, itemsPerPage, pageCount, totalElements, currentPage, makes } = payload;
			addVehicleToListKeyAndUpdateEntities({
				newVehicleList: vehicles,
				state,
				listKey: LIST_KEYS.watchlist,
				shouldDecode: true,
			});
			state.searchLists[LIST_KEYS.watchlist].isLoading = false;
			state.searchLists[LIST_KEYS.watchlist].isReady = true;
			state.searchLists[LIST_KEYS.watchlist].itemsPerPage = itemsPerPage;
			state.searchLists[LIST_KEYS.watchlist].pageCount = pageCount;
			state.searchLists[LIST_KEYS.watchlist].totalElements = totalElements;
			state.searchLists[LIST_KEYS.watchlist].currentSearch.params.page = currentPage;
			state.searchLists[LIST_KEYS.watchlist].makes = makes;
			state.searchLists[LIST_KEYS.watchlist].vehiclesBidIdsWithUpdates =
				filterWatchedVehiclesFromNotificationList(
					state.searchLists[LIST_KEYS.watchlist].vehiclesBidIdsWithUpdates,
					vehicles,
				);
			state.searchLists[LIST_KEYS.watchlist].pagesToHighlight = updatePagesToHighlight(
				state.searchLists[LIST_KEYS.watchlist].allWatchlistVehiclesBidIds,
				state.searchLists[LIST_KEYS.watchlist].vehiclesBidIdsWithUpdates,
				itemsPerPage,
			);
			// needed when getting preview page because of last element of page deletion
			if (state.searchLists[LIST_KEYS.watchlist].currentSearch.params.page > pageCount) {
				state.searchLists[LIST_KEYS.watchlist].currentSearch.params.page = pageCount;
			}

			state.searchLists[LIST_KEYS.watchlist].errorMessage = null;
		});
		builder.addCase(getWatchlistPage.rejected, (state, { meta, payload }) => {
			if (!meta.aborted) {
				const { errorMessage } = payload;
				state.searchLists[LIST_KEYS.watchlist].isLoading = false;
				state.searchLists[LIST_KEYS.watchlist].isReady = true;
				state.searchLists[LIST_KEYS.watchlist].errorMessage = errorMessage;
			}
		});
	},
});

export const buyerTimedSaleVehiclesSliceActions = buyerTimedSaleVehiclesSlice.actions;
export const {
	/* Export ///NEW ACTIONS */
	startBid,
	endBid,
	updateBid,
	setOvertimeCompleted,
	removeVehicleFromCurrentPage,
	addVehicleToOfferList,
	addToWatchlist,
	removeFromWatchlist,
	fetchWatchlistVehiclesBidIdsSuccess,
	initFocussedTab,
	setFocussedTab,
	setWatchlistElementNotification,
	clearFeaturedVehicleCampaign,
	clearAllVehiclesList,
} = buyerTimedSaleVehiclesSlice.actions;

export const {
	selectById: selectVehicleById,
	selectIds: selectVehicleIds,
	selectEntities: selectVehicleEntities,
	selectAll: selectAllVehicles,
	selectTotal: selectTotalVehicles,
} = vehiclesAdapter.getSelectors((state) => state.buyerVehicles);

export default buyerTimedSaleVehiclesSlice.reducer;
