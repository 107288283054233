import { createContext, useContext } from 'react';

const urls = {
	signUpUrl: '/sign_up',
	signInUrl: '/sign_in',
	passwordForgot: '/password/forgot'
};

const urlsContext = createContext(urls);

export const useUrlsContext = () => useContext(urlsContext);

export default urlsContext;
