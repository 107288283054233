import { deal_working, live_or_extended, sold } from 'helpers/timedSales/auctionStatusScenarios';

import { USE_BEST_OFFERER_FROM_DEALERSHIP } from 'constants/localFlags';

export const isCurrentUserTheTopOfferer = ({ topOffererId, topOffererDealershipId, userIds, dealershipsId }) => {
	if (USE_BEST_OFFERER_FROM_DEALERSHIP) {
		return topOffererDealershipId && dealershipsId.includes(topOffererDealershipId);
	}
	return topOffererId && userIds.includes(topOffererId);
};

export const calculateSummaryFromList = ({ bidSalesThatUserHasOfferer, userIds, dealershipsId }) => {
	const offerSummary = bidSalesThatUserHasOfferer.reduce(
		({ leaderCount, outbiddedCount, wonCount, lostCount, counterCount }, bidSale) => {
			const { top_offerer_id: topOffererId, top_offerer_dealership_id: topOffererDealershipId } = bidSale;
			const isUserTheTopOfferer = isCurrentUserTheTopOfferer({
				topOffererId,
				topOffererDealershipId,
				userIds,
				dealershipsId,
			});

			if (live_or_extended(bidSale.auction_status)) {
				if (deal_working(bidSale.auction_status)) {
					isUserTheTopOfferer ? counterCount++ : lostCount++;
				} else {
					isUserTheTopOfferer ? leaderCount++ : outbiddedCount++;
				}
			}
			if (sold(bidSale.auction_status)) {
				isUserTheTopOfferer ? wonCount++ : lostCount++;
			}

			return { leaderCount, outbiddedCount, wonCount, lostCount, counterCount };
		},
		{ leaderCount: 0, outbiddedCount: 0, wonCount: 0, lostCount: 0, counterCount: 0 },
	);

	return offerSummary;
};
