/* eslint-disable camelcase */
import { BID_SALE_AUCTION_STATUS } from 'constants/bidSale';

export const expired = (vehicleStatus) => vehicleStatus === BID_SALE_AUCTION_STATUS.expired;

export const not_expired = (vehicleStatus) => vehicleStatus !== BID_SALE_AUCTION_STATUS.expired;

export const pending_or_future = (auctionStatus) =>
	auctionStatus === BID_SALE_AUCTION_STATUS.pending || auctionStatus === BID_SALE_AUCTION_STATUS.future;

export const live_and_no_highest_offer = (auctionStatus, highestBid) => live(auctionStatus) && !highestBid;

export const live_and_highest_offer = (auctionStatus, highestBid) => live(auctionStatus) && highestBid;

export const ended = (auctionStatus) => auctionStatus === BID_SALE_AUCTION_STATUS.ended;

export const failed = (auctionStatus) => auctionStatus === BID_SALE_AUCTION_STATUS.failed;

export const overtime = (auctionStatus) => auctionStatus === BID_SALE_AUCTION_STATUS.overtime;

export const sold = (auctionStatus) => auctionStatus === BID_SALE_AUCTION_STATUS.sold;

export const live = (auctionStatus) => auctionStatus === BID_SALE_AUCTION_STATUS.live;

export const deal_working = (auctionStatus) => auctionStatus === BID_SALE_AUCTION_STATUS.deal_working;

export const live_or_extended = (auctionStatus) =>
	live(auctionStatus) || overtime(auctionStatus) || deal_working(auctionStatus);

export const dw_and_countered = (auctionStatus, countered) => deal_working(auctionStatus) && countered;

export const dw_and_not_countered = (auctionStatus, countered) => deal_working(auctionStatus) && !countered;
