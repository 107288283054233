import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance, { DefaultRoutingProvider } from '@bugsnag/browser-performance';
import handledErrors, { isMinifiedReactError } from './handledErrors';
import { getState } from 'store/store';
import { loggedUSerSelector } from 'store/slices/loggedUsers/selector';

const doNotLogEnvs = ['test', 'development'];

const logEnv = (env) => {
	if (env && !doNotLogEnvs.includes(env)) {
		return [env];
	}
	return [];
};

export function resolveRoute (url) {
	const pathname = url.pathname;
	if (pathname.match(/\/vehicle_detail\/\d+/i)) {
		return '/vehicle_detail/:id';
	}

	if (pathname.match(/\/condition-report\/\w+/i)) {
		return '/condition-report/:id';
	}

	if (pathname.match(/\/webapp\/gtv_offers\/[0-9a-f-]+\/redemption_verification$/i)) {
		return '/webapp/gtv_offers/:offerId/redemption_verification';
	}

	if (pathname.match(/\/webapp\/gtv_offers\/[0-9a-f-]+\/redemption_authentication$/i)) {
		return '/webapp/gtv_offers/:offerId/redemption_authentication';
	}

	if (pathname.startsWith('/en') || pathname.startsWith('/es')) {
		return pathname.replace(/^\/(en|es)/, '');
	}

	return pathname;
}

function initializeBugsnagClient () {
	const isServerSide = typeof window === 'undefined';
	const bugsnagApiKey = isServerSide ? process.env.BUGSNAG_SERVER_API_KEY : process.env.BUGSNAG_BROWSER_API_KEY;
	const releaseStage =
		process.env.ENV === 'ondemand' ? process.env.ONDEMAND_NAMESPACE || 'ondemand' : process.env.ENV;

	const config = {
		apiKey: bugsnagApiKey || 'development',
		releaseStage: releaseStage,
		enabledReleaseStages: logEnv(releaseStage),
		metadata: {},
		appVersion: process.env.VERSION || 'development',
		onError: onError,
	};

	// If it is already started it wont start again
	Bugsnag.start({ ...config, plugins: [new BugsnagPluginReact(React)] });

	if (!isServerSide) {
		BugsnagPerformance.start({
			...config,
			routingProvider: new DefaultRoutingProvider(resolveRoute),
			autoInstrumentNetworkRequests: false,
			settleIgnoreUrls: [/\/_next\/.*/],
		});
	}
}

export const onError = (event) => {
	let primaryErrorMessage;

	const user = loggedUSerSelector(getState());

	if (user?.id) {
		const { id, email, dealership_name } = user;
		event.setUser(id, email, dealership_name);
	}

	try {
		primaryErrorMessage = event.errors[0].errorMessage;
	} catch {
		primaryErrorMessage = null;
	}

	if (handledErrors.includes(primaryErrorMessage) || isMinifiedReactError(primaryErrorMessage)) {
		event.unhandled = false;
	}
};

export default initializeBugsnagClient;
