import STATUS_CODES from 'constants/statusCodes';

const ErrorImage = ({ statusCode }) => {
	if (statusCode === STATUS_CODES.INTERNAL_SERVER_ERROR) {
		return (
			<svg width="383" height="146" viewBox="0 0 383 146" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M93.0883 2.99999V28.1H36.3883L35.7883 56.1H51.8883C84.4883 56.1 100.888 74.8 100.888 98.2C100.888 128.3 78.0883 145.5 49.7883 145.5C28.8883 145.5 11.7883 137.7 0.188281 124.6L15.3883 105.7C22.9883 114 36.6883 120.5 49.5883 120.5C65.1883 120.5 72.8883 111.3 72.8883 100.2C72.8883 89.7 66.2883 80.5 44.3883 80.5H11.0883L12.5883 2.99999H93.0883ZM180.69 0.499993C214.09 0.499993 241.09 27.7 241.09 73C241.09 118.3 214.09 145.5 180.69 145.5C147.19 145.5 120.39 118.3 120.39 73C120.39 27.7 147.19 0.499993 180.69 0.499993ZM180.69 26.4C162.79 26.4 148.49 42.4 148.49 73C148.49 103.6 162.79 119.6 180.69 119.6C198.69 119.6 212.89 103.6 212.89 73C212.89 42.4 198.69 26.4 180.69 26.4ZM321.901 0.499993C355.301 0.499993 382.301 27.7 382.301 73C382.301 118.3 355.301 145.5 321.901 145.5C288.401 145.5 261.601 118.3 261.601 73C261.601 27.7 288.401 0.499993 321.901 0.499993ZM321.901 26.4C304.001 26.4 289.701 42.4 289.701 73C289.701 103.6 304.001 119.6 321.901 119.6C339.901 119.6 354.101 103.6 354.101 73C354.101 42.4 339.901 26.4 321.901 26.4Z"
					fill="#0A1B5F"
				/>
			</svg>
		);
	}

	if (statusCode === STATUS_CODES.NOT_FOUND) {
		return (
			<svg width="432" height="200" viewBox="0 0 432 200" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M221.455 23.0308C203.213 22.5137 185.385 28.5181 171.173 39.9654C170.906 40.1949 170.689 40.4764 170.535 40.7928C170.381 41.1091 170.293 41.4536 170.276 41.8051C170.26 42.1567 170.315 42.5079 170.439 42.8372C170.563 43.1666 170.753 43.4672 170.997 43.7206L188.278 61.0018C188.7 61.4181 189.254 61.6728 189.844 61.721C190.435 61.7693 191.023 61.608 191.506 61.2656C199.941 55.0895 210.204 51.9222 220.653 52.2707C228.088 52.4648 235.373 54.4039 241.921 57.9315C248.468 61.4591 254.095 66.4764 258.347 72.5786C262.599 78.6809 265.357 85.6971 266.399 93.0614C267.44 100.426 266.736 107.932 264.343 114.974C261.95 122.016 257.936 128.397 252.623 133.602C247.31 138.807 240.849 142.69 233.759 144.938C226.67 147.187 219.151 147.737 211.809 146.545C204.468 145.353 197.51 142.452 191.496 138.076C191.013 137.734 190.424 137.573 189.834 137.621C189.244 137.669 188.689 137.924 188.268 138.34L170.997 155.627C170.753 155.88 170.563 156.181 170.439 156.51C170.315 156.839 170.26 157.19 170.276 157.542C170.293 157.894 170.381 158.238 170.535 158.554C170.689 158.871 170.906 159.152 171.173 159.382C180.65 167.024 191.81 172.301 203.731 174.775C215.652 177.248 227.99 176.848 239.725 173.608C251.461 170.367 262.256 164.379 271.218 156.139C280.18 147.899 287.051 137.643 291.264 126.22C295.476 114.798 296.908 102.536 295.442 90.4503C293.975 78.3644 289.652 66.8013 282.83 56.7175C276.008 46.6337 266.884 38.319 256.211 32.4612C245.538 26.6033 233.624 23.3708 221.455 23.0308Z"
					fill="#0A1B5F"
				/>
				<path
					d="M180.696 130.659C181.114 130.239 181.37 129.684 181.418 129.094C181.466 128.503 181.304 127.914 180.96 127.432C175.06 119.346 171.881 109.595 171.881 99.5857C171.881 89.5764 175.06 79.8258 180.96 71.7398C181.302 71.2566 181.463 70.6682 181.415 70.078C181.367 69.4877 181.112 68.9333 180.696 68.5122L158.697 46.5034C158.444 46.2592 158.143 46.0693 157.814 45.9454C157.485 45.8214 157.133 45.7659 156.782 45.7824C156.43 45.7988 156.086 45.8869 155.769 46.0411C155.453 46.1953 155.172 46.4125 154.942 46.6793C142.694 61.6045 136 80.3146 136 99.6219C136 118.929 142.694 137.639 154.942 152.564C155.172 152.831 155.453 153.048 155.769 153.203C156.086 153.357 156.43 153.445 156.782 153.461C157.133 153.478 157.485 153.422 157.814 153.298C158.143 153.174 158.444 152.985 158.697 152.74L180.696 130.659Z"
					fill="#0061FF"
				/>
				<path
					d="M93.2 87.9V125.2H112.7V150.4H93.2V176H66.2V150.4H4.1V130.8L53.2 36.1H83.2L36.4 125.2H66.2V87.9H93.2Z"
					fill="#0A1B5F"
				/>
				<path
					d="M408.2 87.9V125.2H427.7V150.4H408.2V176H381.2V150.4H319.1V130.8L368.2 36.1H398.2L351.4 125.2H381.2V87.9H408.2Z"
					fill="#0A1B5F"
				/>
			</svg>
		);
	}
};

export default ErrorImage;
