import { authenticatedRestClient } from 'helpers/restclient';
import { getAuthHeadersFromRedux } from 'helpers/restclient/auth';
import { buildParamsURL } from 'helpers/search';

export default class Service {
	internalInstance;

	static get instance() {
		if (!this.internalInstance) this.internalInstance = new Service();
		return this.internalInstance;
	}

	#endpoints = {
		featuredVehicleCampaign: `/api/featured_vehicles_campaigns`,
		getCloseFeaturedVehicleCampaign: (campaignId) => `/api/featured_vehicles_campaigns/${campaignId}/close`,
	};

	constructor() {
		this.restClient = authenticatedRestClient;
	}

	getFeaturedVehicleCampaign = ({ searchListParams, cancelToken } = {}) => {
		const endpoint = `${this.#endpoints.featuredVehicleCampaign}?${buildParamsURL(
			searchListParams || {},
		)}`;
		return this.restClient.get(endpoint, {}, getAuthHeadersFromRedux(), cancelToken);
	};

	closeFeaturedVehicleCampaign = ({ campaignId, sourceTab, context, cancelToken }) => {
		const endpoint = this.#endpoints.getCloseFeaturedVehicleCampaign(campaignId);
		return this.restClient.post(
			endpoint,
			{ source_tab: sourceTab, context },
			getAuthHeadersFromRedux(),
			cancelToken,
		);
	};
}
