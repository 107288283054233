import { SvgPositioner } from './styles';

const RigthSemiCircle = () => (
	<svg width="508" height="539" viewBox="0 0 508 539" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="OpenLane" opacity="0.2">
			<path
				id="vector"
				d="M527.119 1.1895L527.12 1.18951C602.034 3.27962 675.374 23.1547 741.072 59.1708C806.77 95.1868 862.938 146.309 904.932 208.307C946.925 270.306 973.535 341.4 982.563 415.709C991.59 490.017 982.774 565.405 956.845 635.635C930.915 705.866 888.617 768.921 833.45 819.585C778.282 870.249 711.829 907.066 639.59 926.991C567.35 946.915 491.399 949.374 418.019 934.164C344.644 918.955 275.948 886.517 217.614 839.533C216.077 838.21 214.826 836.587 213.937 834.765C213.045 832.939 212.536 830.949 212.441 828.92C212.346 826.89 212.666 824.862 213.383 822.96C214.098 821.061 215.194 819.328 216.602 817.866C216.605 817.864 216.607 817.861 216.61 817.859L323.132 711.368C323.133 711.367 323.134 711.366 323.135 711.365C325.565 708.967 328.764 707.5 332.169 707.222C335.574 706.944 338.967 707.872 341.755 709.842C378.979 736.895 422.049 754.829 467.49 762.198C512.931 769.567 559.471 766.164 603.354 752.264C647.237 738.364 687.234 714.355 720.118 682.175C753.002 649.995 777.853 610.545 792.665 567.008C807.477 523.47 811.835 477.065 805.388 431.536C798.941 386.006 781.869 342.628 755.55 304.901C729.231 267.174 694.402 236.155 653.872 214.346C613.344 192.537 568.252 180.549 522.232 179.349C457.558 177.195 394.031 196.776 341.819 234.957C339.032 236.929 335.638 237.857 332.233 237.579C328.827 237.301 325.629 235.834 323.198 233.435C323.197 233.434 323.196 233.433 323.196 233.432L216.61 126.974C216.607 126.971 216.605 126.969 216.603 126.967C215.194 125.505 214.099 123.771 213.383 121.872L212.447 122.225L213.383 121.872C212.666 119.97 212.346 117.943 212.441 115.913C212.536 113.883 213.045 111.894 213.937 110.067C214.826 108.245 216.077 106.623 217.614 105.3C305.096 34.924 414.836 -1.98981 527.119 1.1895Z"
				stroke="#0061FF"
				strokeWidth="2"
			/>
			<path
				id="vector_2"
				d="M279.177 653.603C278.898 657.007 277.422 660.203 275.009 662.624L139.321 798.661C139.318 798.663 139.316 798.665 139.314 798.667C137.85 800.074 136.114 801.169 134.213 801.884C132.308 802.6 130.277 802.92 128.245 802.825C126.212 802.73 124.22 802.221 122.391 801.331C120.565 800.442 118.94 799.191 117.615 797.655C42.212 705.875 1 590.822 1 472.097C1 353.373 42.2118 238.321 117.614 146.541C118.94 145.004 120.565 143.753 122.391 142.864C124.22 141.973 126.212 141.465 128.245 141.37C130.277 141.275 132.308 141.595 134.213 142.311C136.114 143.026 137.85 144.121 139.314 145.528C139.316 145.53 139.318 145.532 139.321 145.534L275.006 281.122C275.007 281.122 275.007 281.123 275.008 281.123C277.41 283.551 278.879 286.746 279.157 290.147C279.435 293.547 278.506 296.936 276.533 299.72C240.018 349.711 220.343 409.993 220.343 471.874C220.343 533.756 240.019 594.039 276.534 644.03C278.519 646.81 279.455 650.201 279.177 653.603Z"
				stroke="#0061FF"
				strokeWidth="2"
			/>
		</g>
	</svg>
);

const LeftSemiCircle = () => (
	<svg width="299" height="213" viewBox="0 0 299 213" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.2">
			<path
				d="M-21.1079 1.13203L-21.1075 1.13204C30.988 2.58823 81.9885 16.4353 127.675 41.528C173.362 66.6207 212.422 102.238 241.624 145.433C270.827 188.629 289.332 238.161 295.61 289.933C301.887 341.705 295.757 394.228 277.725 443.159C259.694 492.09 230.279 536.021 191.915 571.319C153.551 606.617 107.34 632.268 57.1042 646.149C6.86855 660.031 -45.9481 661.744 -96.9767 651.147C-148 640.551 -195.77 617.953 -236.335 585.22C-237.372 584.325 -238.216 583.227 -238.816 581.995C-239.419 580.758 -239.763 579.411 -239.827 578.037C-239.892 576.662 -239.675 575.289 -239.19 574.001C-238.707 572.716 -237.967 571.543 -237.016 570.553C-237.014 570.551 -237.012 570.549 -237.01 570.547L-162.87 496.29C-162.87 496.29 -162.87 496.29 -162.87 496.29C-161.229 494.667 -159.069 493.674 -156.769 493.486C-154.471 493.298 -152.18 493.926 -150.298 495.259C-124.35 514.151 -94.3278 526.675 -62.6533 531.821C-30.9765 536.968 1.46574 534.591 32.0559 524.883C62.646 515.175 90.5273 498.408 113.45 475.934C136.373 453.459 153.696 425.908 164.021 395.502C174.346 365.096 177.384 332.688 172.89 300.891C168.396 269.094 156.496 238.8 138.149 212.452C119.803 186.104 95.524 164.44 67.2718 149.209C39.0206 133.978 7.58751 125.606 -24.4919 124.767C-69.5752 123.263 -113.859 136.938 -150.255 163.603C-152.137 164.936 -154.427 165.563 -156.725 165.375C-159.024 165.187 -161.183 164.195 -162.824 162.572C-162.825 162.571 -162.825 162.571 -162.826 162.57L-237.01 88.3361C-237.012 88.334 -237.014 88.3319 -237.016 88.3298C-237.967 87.3402 -238.707 86.1672 -239.19 84.8821C-239.675 83.5942 -239.892 82.2209 -239.827 80.8463C-239.763 79.4717 -239.419 78.1247 -238.816 76.8878C-238.216 75.6556 -237.372 74.5585 -236.335 73.6632C-175.5 24.6339 -99.1886 -1.08297 -21.1079 1.13203Z"
				stroke="#0061FF"
				strokeWidth="2"
			/>
		</g>
	</svg>
);

const BackgroundCircles = () => (
	<SvgPositioner>
		<LeftSemiCircle />
		<RigthSemiCircle />
	</SvgPositioner>
);


export default BackgroundCircles;
