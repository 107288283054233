/* eslint-disable camelcase */
import { BlcToastContainer } from '@Backlot-Cars/archie';
import { BlcFavicon } from 'components/BlcFavicon';
import NavigationWrapper from 'components/NavigationWrapper';
import StockwaveScript from 'components/Stockwave/StockwaveScript';
import { getErrorBoundary } from 'helpers/errorNotifier';
import SegmentSnippet from 'helpers/integrations/SegmentSnippet/Snippet';
import { appWithTranslation } from 'next-i18next';
import App from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { wrapper } from 'store/';
import { loggedUSerSelector } from 'store/slices/loggedUsers/selector';
import { getState } from 'store/store';
import AppGlobalStyles from '../globalStyles';
import Error from './_error';
import initializeBugsnagClient from '/lib/bugsnag';

import 'public/fonts/fonts.css';
import 'react-toastify/dist/ReactToastify.css';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

if (process.env.NODE_ENV !== 'production') {
	//  Mock Service Worker
	if (process.env.MSW_ENABLED) {
		require('__msw__');
	}
}

initializeBugsnagClient();

const ErrorBoundary = getErrorBoundary();

const MyApp = ({ Component, pageProps }) => {
	const user = loggedUSerSelector(getState());

	const loggedUser = Boolean(user?.id);

	return (
		<>
			<ErrorBoundary FallbackComponent={Error}>
				<StockwaveScript />
				<SegmentSnippet />
				<AppGlobalStyles />
				<BlcFavicon />
				{loggedUser ?
					<NavigationWrapper user={user}>
						<Component {...pageProps} />
					</NavigationWrapper>
				:	<Component {...pageProps} />}
				<BlcToastContainer top={loggedUser ? 56 : undefined} />
			</ErrorBoundary>
		</>
	);
};

MyApp.getInitialProps = async (appContext) => {
	const appProps = await App.getInitialProps(appContext);
	return { ...appProps };
};

export default appWithTranslation(wrapper.withRedux(MyApp));
