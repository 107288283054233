export const BID_SALE_AUCTION_STATUS = {
	pending: 'pending',
	live: 'live',
	overtime: 'overtime',
	deal_working: 'deal_working',
	ended: 'ended',
	future: 'future',
	sold: 'sold',
	failed: 'failed',
	expired: 'expired',
};

export const BID_SALE_SOURCE_TAB = {
	auction: 'auction',
	offers: 'offers',
	watchlist: 'watchlist',
	hidden: 'hidden',
};
