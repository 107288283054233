import Head from 'next/head';
import prop, { BlcMetaDefaultProps } from 'components/BlcMeta/props.js';

const BlcMeta = (props) => (
	<Head>
		<meta charSet="utf-8" />
		<meta httpEquiv="X-UA-Compatible" content="IE=edge" />
		<meta name="description" content={props.description || `OPENLANE | ${props.title}`} />
		<meta property="og:type" content={props.article} />
		<meta property="og:title" content={props.title} />
		<meta property="og:site_name" content="OPENLANE US" />
		<meta property="og:image" content={props.imageURL} />
		<meta property="og:description" content={props.description} />
		<meta name="HandheldFriendly" content={props.handheld.toString()} />
		<meta
			name="viewport"
			content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"
		/>
		{props.robotsMeta && <meta name="robots" content="noindex" />}
		<link rel="icon" type="image/png" href={props.favicon} />
		<title>{props.title}</title>
	</Head>
);

BlcMeta.propTypes = prop;

BlcMeta.defaultProps = BlcMetaDefaultProps;

export default BlcMeta;
