import { createSlice } from '@reduxjs/toolkit';
import {
	setOvertimeCompleted as setOvertimeCompletedHelper,
	updateOfferStatus,
} from 'store/helpers/vehicleOfferUpdates';

import { getVehicleOfferState } from './thunks';

export const initialState = {
	offerStatus: {
		isLoading: false,
		errorMessage: '',
		data: {},
	},
	openAsModal: {
		isOpened: false,
		vehicleId: null,
	},
};

export const vehicleDetailPage = createSlice({
	name: 'vehicleDetailPage',
	initialState,
	reducers: {
		updateOffer: (state, { payload: { updatedOffer, loggedUser } }) => {
			const vehicleOfferStatusChanges = updateOfferStatus({
				currentOfferState: state.offerStatus.data,
				updatedOfferState: updatedOffer,
				loggedUser,
			});

			state.offerStatus.data = {
				...state.offerStatus.data,
				...vehicleOfferStatusChanges,
			};
		},
		// used to force re render and calculate new status once sell today pass to extension
		forceReRender: (state) => {
			const localVersion = state.offerStatus.data.localVersion || 0;
			if (localVersion < 100) { // setting a limit to about infinite loop in case of being called in a loop or recursion.
				state.offerStatus.data = {
					...state.offerStatus.data,
					localVersion: localVersion + 1,
				};
			}
		},
		setOvertimeCompleted: (state) => {
			const updatedOffer = setOvertimeCompletedHelper({ currentOfferState: state.offerStatus.data });
			state.offerStatus.data = updatedOffer;
		},

		openVdpAsDrawer: (state, { payload: { vehicleId, campaignId } }) => {
			state.openAsModal = {
				isOpened: true,
				vehicleId,
				campaignId,
			};
		},
		closeVdpAsDrawer: (state) => {
			state.openAsModal = initialState.openAsModal;
			state.offerStatus = initialState.offerStatus;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getVehicleOfferState.pending, (state) => {
			state.offerStatus.isLoading = true;
			state.offerStatus.errorMessage = '';
		});
		builder.addCase(getVehicleOfferState.fulfilled, (state, { payload: { offerStatus } }) => {
			state.offerStatus.isLoading = false;
			state.offerStatus.data = offerStatus;
		});
		builder.addCase(getVehicleOfferState.rejected, (state, { meta, payload }) => {
			if (!meta.aborted) {
				state.offerStatus.isLoading = false;
				state.offerStatus.errorMessage = payload?.errorMessage || 'something went wrong';
			}
		});
	},
});

export const { updateOffer, forceReRender, setOvertimeCompleted, openVdpAsDrawer, closeVdpAsDrawer } =
	vehicleDetailPage.actions;

export default vehicleDetailPage.reducer;
