import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	referralSubmitted: false,
	referralData: {
		dealershipName: '',
		dealershipType: '',
		email: '',
		firstName: '',
		lastName: '',
		city: '',
		state: '',
		zipCode: '',
		phoneNumber: ''
	}
};

export const referAFriendSlice = createSlice({
	name: 'referAFriend',
	initialState: initialState,
	reducers: {
		setReferralSubmitted: (state, action) => {
			state.referralSubmitted = action.payload;
		},
		setReferralData: (state, action) => {
			state.referralData = { ...state.referralData, ...action.payload };
		},
		resetReferralData: (state) => {
			state.referralData = initialState.referralData;
		}
	}
});

export const { setReferralSubmitted, setReferralData, resetReferralData } = referAFriendSlice.actions;

export default referAFriendSlice.reducer;