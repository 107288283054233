import { isServerSide } from 'helpers/isServerSide';
import { redirect } from 'helpers/redirect';

export const onSuccess = (response) => response;

export const onError = (error) => {
	const redirectTo = error?.response?.data?.redirect_to;
	if (redirectTo) {
		redirect(redirectTo);
	}
	return Promise.reject(error);
};

export const setupRedirectInterceptor = (restClientInstance) =>
	!isServerSide() && restClientInstance._axios.interceptors.response.use(onSuccess, onError);
