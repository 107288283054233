import { authenticatedRestClient } from 'helpers/restclient';
import { getAuthHeaders } from 'helpers/restclient/auth';

export default class ActivitiesService {
	static instance = new ActivitiesService();

	_endpoints = {
		getAllActivities: '/api/activities',
		deleteAllActivities: '/api/activities/delete_all',
		readAllActivities: '/api/activities/read',
		deleteActivity: (activityId) => `/api/activities/${activityId}`,
	};

	constructor() {
		this.restClient = authenticatedRestClient;
	}

	getActivities = (user) =>
		this.restClient.get(this._endpoints.getAllActivities, {}, getAuthHeaders(user));

	deleteActivity = (user, activityId) =>
		this.restClient.delete(
			this._endpoints.deleteActivity(activityId),
			getAuthHeaders(user),
		);

	deleteAllActivities = (user) =>
		this.restClient.post(this._endpoints.deleteAllActivities, {}, getAuthHeaders(user));

	readAllActivities = (user) =>
		this.restClient.post(this._endpoints.readAllActivities, {}, getAuthHeaders(user));
}
