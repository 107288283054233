import { encrypt } from 'helpers/crypto';

export default async (ctx, required) => {
	const baseUrl = process.env.RESTURL_BASE;
	const url = encrypt(baseUrl, process.env.CRYPTO_ALGORITHM, process.env.CRYPTO_KEY, process.env.CRYPTO_IV);

	if (required) {
		return `${baseUrl}/sso_frontend?sso_redirect=${encodeURIComponent(url)}`;
	}

	return `${baseUrl}/sso_frontend_optional/${encodeURIComponent(url)}`;
};
