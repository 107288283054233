import { createAsyncThunk } from '@reduxjs/toolkit';
import BidSalesService from 'services/timedSales/buyside/bidsales.service';
import { calculateSummaryFromList } from 'store/slices/offerSummary/helper';

export const loadBuyerInitialOfferSummary = createAsyncThunk('loadBuyerInitialOfferSummary', async ({ loggedUser }) => {
	const { user_id: loggedUserId, dealership_id: dealershipId } = loggedUser;
	const { data } = await BidSalesService.instance.offerRefresh({
		vehicles: [],
		loggedUser,
		focussedSearchListKey: 'auction',
		reason: 'first_load',
		includeSummary: true,
	});
	const userSubordinates = null; // it was not working, I just hardcode it to avoid using wrong selector

	const userIds = !userSubordinates ? [loggedUserId] : userSubordinates.map((subordinate) => subordinate.id);
	const dealershipsId = !userSubordinates ? [dealershipId] : userSubordinates.map((subordinate) => subordinate.id);

	const summary = data.data.summary;

	return {
		bidSalesArr: summary,
		stats: calculateSummaryFromList({ bidSalesThatUserHasOfferer: summary, userIds, dealershipsId }),
		userIds,
		dealershipsId,
	};
});
