/* eslint-disable camelcase */
import * as snippet from '@segment/snippet';
import { QA_ENVIRONMENTS } from 'constants/environments';
import Script from 'next/script';
import { notifyError } from '../../errorNotifier';
import { useEffect } from 'react';
import { useState } from 'react';
import useValidateSegment from 'hooks/useValidateSegment';

export default function SegmentSnippet() {
	const enableSegment = useValidateSegment();

	const [segmentSnippet, setSegmentSnippet] = useState();
	const options = {
		apiKey: process.env.SEGMENT_WRITE_KEY,
		page: false,
	};
	const { ENV } = process.env;

	// this is to force segment to be loaded on client side
	useEffect(() => {
		if (enableSegment) {
			try {
				const snippetFromSegment = QA_ENVIRONMENTS.includes(ENV) ? snippet.max(options) : snippet.min(options);
				setSegmentSnippet(snippetFromSegment);
			} catch {
				notifyError('Error loading segment snippet');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableSegment]);

	return segmentSnippet ? <Script dangerouslySetInnerHTML={{ __html: segmentSnippet }} id="segment-snippet" /> : null;
}
