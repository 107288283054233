import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
	getDefaultSearchListKeyForContext,
	getListContextFromSearchListKey,
	getSourceTabFromSearchListKey,
} from 'helpers/search';
import BuysideSearchService from 'services/timedSales/buyside/search.service';
import { getDraftFilterState } from 'store/slices/buyers/filters/filtersSelectors';
import { getDefaultParams, getSanitizedFilterParams } from './helpers';

const getCatchSavedSearch = async (savedSearchId) => {
	try {
		// if saved search cannot be fetched we should continue with no issue, we do not block filters due to this issue.
		const savedSearchForId = await BuysideSearchService.instance.getSavedSearch(savedSearchId);
		return savedSearchForId;
	} catch {}
};

export const loadFiltersForTab = createAsyncThunk(
	'loadFiltersForTab',
	async ({ context, searchListKey, enableFilters, initialUrlQueryParam }) => {
		if (!enableFilters) {
			return {
				sections: [],
				hotfilters: [],
				sortingOptions: [],
				defaultParams: {},
				params: {},
			};
		}

		const requests = [];
		let savedSearchParams = {};

		const sourceTab = getSourceTabFromSearchListKey(searchListKey);
		requests.push(BuysideSearchService.instance.getFilters({ context, sourceTab }));

		// all tabs call this thunk to initialize filters but only the one that is on URL will pre-populate store from url query params.
		const shouldLoadParamsFromUrl =
			(initialUrlQueryParam?.tab && initialUrlQueryParam?.tab === searchListKey) ||
			(!initialUrlQueryParam?.tab && searchListKey === getDefaultSearchListKeyForContext(context));

		if (shouldLoadParamsFromUrl) {
			if (initialUrlQueryParam?.saved_search_id) {
				requests.push(getCatchSavedSearch(initialUrlQueryParam?.saved_search_id));
			}
		}
		const [sectionsResponse, savedSearchFromId] = await Promise.all(requests);

		const { sections, sort_strategies, hotfilters } = sectionsResponse.data;
		if (savedSearchFromId) savedSearchParams = savedSearchFromId.params;

		const defaultParams = getDefaultParams(sections);

		return {
			sections,
			sortingOptions: sort_strategies,
			defaultParams,
			hotfilters,
			params: shouldLoadParamsFromUrl
				? getSanitizedFilterParams({ ...savedSearchParams, ...initialUrlQueryParam }, defaultParams, sections)
				: defaultParams,
		};
	},
);

// It returns how many vehicles match with draft filter state
export const searchPreview = createAsyncThunk('searchPreview', async ({ searchListKey, isAuction }, thunkAPI) => {
	const source = axios.CancelToken.source();
	thunkAPI.signal.addEventListener('abort', () => {
		source.cancel();
	});
	const draftState = getDraftFilterState(thunkAPI.getState(), searchListKey);
	const filters = {
		...draftState,
		source_tab: getSourceTabFromSearchListKey(searchListKey),
		context: getListContextFromSearchListKey(searchListKey),
	};
	const { data } = await BuysideSearchService.instance.searchPreview(filters, isAuction, source.token);
	const totalMatches = data?.metadata?.total_count;
	const changedFilters = data?.applied_filters?.filters;

	return {
		totalMatches,
		changedFilters,
	};
});
