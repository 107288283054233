import { createContext, useContext } from 'react';

const user = {
	auth: false,
	jwt: '',
	loginURL: '/sign_in',
};

const userContext = createContext(user);

export const useUserContext = () => useContext(userContext);

export default userContext;
