import { media } from 'public/styles';
import styled from 'styled-components';


export const Background = styled.div`
	border-radius: 16px;
	margin: 0 16px;
	background-color: #F1FCFF;
	flex: 1;
	min-height: calc(100vh - 113px);
	position: relative;
	display: grid;

	@media ${media.smaller} {
		justify-content: center;
		min-height: calc(100vh - 72px);
		margin: 0;
	}
`;
