import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	correction: 0,
};

export const atomicTimeSlice = createSlice({
	name: 'atomicTime',
	initialState,
	reducers: {
		updateCorrection: (state, { payload: { correction } }) => {
			state.correction = correction;
		},
	},
});

export const { updateCorrection } = atomicTimeSlice.actions;

export default atomicTimeSlice.reducer;
