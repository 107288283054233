import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
	user: {},
};

export const loggedUserSlice = createSlice({
	name: 'loggedUser',
	initialState,
	reducers: {
		setLoggedUserFields: (state, { payload: { loggedUser } }) => {
			state.user = { ...state.user, ...loggedUser };
		},
		clearUser: () => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action) => {
			// just merge the data on hydrate
			return {
				...state,
				...action.payload.loggedUser,
				user: {
					...state.user,
					...action.payload.loggedUser.user,
				},
			};
		});
	},
});

export const { setLoggedUserFields, clearUser } = loggedUserSlice.actions;

export default loggedUserSlice.reducer;
