import { createEntityAdapter } from '@reduxjs/toolkit';
import { BID_SALE_SOURCE_TAB } from 'constants/bidSale';
import { MARKETPLACE_TAB } from 'constants/marketplace';
import { decodeVehicle } from 'helpers/timedSales';
import { isOlderVersion } from 'helpers/timedSales/versionChecker';
import { removeFromVehicleIds } from 'store/helpers/watchlist';

export const vehiclesAdapter = createEntityAdapter();

export const LIST_KEYS = {
	auction: 'auction',
	offers: 'offers',
	watchlist: 'watchlist',
	hidden: 'hidden',
};

export const getKeyFromSourceTab = (sourceTabName) => {
	// marketplace uses same field name for tabName and searchList key
	if (Object.values(MARKETPLACE_TAB).includes(sourceTabName)) return sourceTabName;

	if (sourceTabName === BID_SALE_SOURCE_TAB.hidden) return LIST_KEYS.hidden;
	if (sourceTabName === BID_SALE_SOURCE_TAB.watchlist) return LIST_KEYS.watchlist;
	if (sourceTabName === BID_SALE_SOURCE_TAB.offers) return LIST_KEYS.offers;
	return LIST_KEYS.auction;
};

export const getNotSelectedKeys = (selectedKey) => {
	const listKeysToArray = [...Object.values(LIST_KEYS), ...Object.values(MARKETPLACE_TAB)];
	return listKeysToArray.filter((key) => key !== selectedKey);
};

const discardOutDatedVehicles = ({ entities, vehicles }) => {
	return vehicles.filter(
		(vehicleToAdd) =>
			!entities[vehicleToAdd.id] ||
			!isOlderVersion({
				incomingChanges: vehicleToAdd,
				storedState: entities[vehicleToAdd.id],
			}),
	);
};

export const addVehicleToListKeyAndUpdateEntities = ({
	state,
	newVehicleList,
	listKey,
	isFeaturedVehicleList,
	shouldDecode,
}) => {
	// Save the rest of the list
	const nonSelectedKeys = getNotSelectedKeys(listKey);
	const vehiclesInOtherLists = nonSelectedKeys.reduce((accumulator, nonSelectedKey) => {
		accumulator.push(
			...(state.searchLists[nonSelectedKey]?.vehicleIds.map((vehicleId) => state.entities[vehicleId]) || []),
			...(state.searchLists[nonSelectedKey]?.featured?.vehicleIds.map((vehicleId) => state.entities[vehicleId]) ||
				[]),
		);
		return accumulator;
	}, []);

	const updatedVehicles = discardOutDatedVehicles({ entities: state.entities, vehicles: newVehicleList });
	const newVehicles = shouldDecode ? updatedVehicles.map(decodeVehicle) : updatedVehicles;
	vehiclesAdapter.upsertMany(state, newVehicles);

	// As we overwrite all vehicles on entities, we should re-add the ones that are on the others lists
	for (let vehicle of vehiclesInOtherLists) {
		state.entities[vehicle.id] ||= vehicle;
	}

	const searchList = isFeaturedVehicleList ? state.searchLists[listKey].featured : state.searchLists[listKey];
	searchList.vehicleIds = newVehicleList?.map(({ id }) => id) || [];
	state.ids = [];
};

/**
 * It mutate state and remove vehicle from list
 */
export const removeVehicleFromListKeyAndUpdateEntities = ({ state, vehicleId, listKey }) => {
	const { vehicleIds, totalElements, pageCount } = removeFromVehicleIds({
		vehicleIds: state.searchLists[listKey].vehicleIds,
		vehicleIdToRemove: vehicleId,
		totalElements: state.searchLists[listKey].totalElements,
		itemsPerPage: state.searchLists[listKey].itemsPerPage,
	});

	// this will clear entities and update searchlist.vehicleIds
	const someVehicleWasRemovedFromList = vehicleIds.length !== state.searchLists[listKey].vehicleIds;
	if (someVehicleWasRemovedFromList) {
		const newVehicleList = vehicleIds.map((vehicleId) => state.entities[vehicleId]);
		addVehicleToListKeyAndUpdateEntities({ state, newVehicleList, listKey, shouldDecode: false });
	}

	state.searchLists[listKey].totalElements = totalElements;
	state.searchLists[listKey].pageCount = pageCount;
	state.searchLists[listKey].totalElements;
};
