import { soldBubble, winnerBubble, lostBubble } from '../bubbles';

const getSoldOfferStatus = (currentUserTopOfferer, bestOfferUserData, reserveMet, currentUserHasOffered) => {
	return currentUserTopOfferer
		? {
			...winnerBubble,
			...bestOfferUserData,
			winner: true,
			showUserOffer: true,
		  }
		: {
			...(currentUserHasOffered ? lostBubble : soldBubble),
			...bestOfferUserData,
			ended: true,
		  };
};

export default getSoldOfferStatus;
