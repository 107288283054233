import { createAsyncThunk } from '@reduxjs/toolkit';
import BuysideSearchService from 'services/timedSales/buyside/search.service';
import { multipleOptionsTypes } from 'store/slices/buyers/filters/helpers';

const transformAppliedFiltersToKeyValueFormat = (appliedFilters) => {
	return (appliedFilters || []).reduce((keyValueObj, appliedFilter) => {
		let value = appliedFilter.value;
		if (multipleOptionsTypes.includes(appliedFilter.filter_type)) {
			const actualValue = keyValueObj[appliedFilter.key] || [];
			value = [...actualValue, appliedFilter.value];
		}
		return { ...keyValueObj, [appliedFilter.key]: value };
	}, {});
};

const transformToParamsFormatForSavedSearch = (savedSearch) => ({
	...transformAppliedFiltersToKeyValueFormat(savedSearch.filters),
	order_by: savedSearch?.sort_strategy?.order_by,
	direction: savedSearch?.sort_strategy?.direction,
});

export const getSavedSearch = createAsyncThunk('getSavedSearch', async ({ context, searchListKey, enableFilters }) => {
	if (!enableFilters) return { savedSearches: [] };

	const { data } = await BuysideSearchService.instance.getSavedSearchesNew({ context, source_tab: searchListKey });

	const savedSearches = (data?.data || []).map((savedSearch) => ({
		...savedSearch,
		params: transformToParamsFormatForSavedSearch(savedSearch),
	}));

	return {
		savedSearches,
	};
});
