import { filterTypes } from './index';

export const handleCheckboxChange = ({ checkboxChangePayload, currentFilterParamsState, parentOptionValue }) => {
	const { key, value: optionValue, isChecked } = checkboxChangePayload;
	let didFilterChange = false;

	if (!currentFilterParamsState[key]) currentFilterParamsState[key] = [];
	if (typeof currentFilterParamsState[key] === 'string') {
		currentFilterParamsState[key] = currentFilterParamsState[key].split(',');
	}

	if (isChecked && !currentFilterParamsState[key].includes(optionValue)) {
		currentFilterParamsState[key].push(optionValue);
		didFilterChange = true;

		if (parentOptionValue) {
			//Remove parent from the state when select a child
			//Remove after the BE implement this (the BE should ignore the Parent if a child is selected)
			currentFilterParamsState[key] = currentFilterParamsState[key].filter(
				(currentValue) => currentValue !== parentOptionValue,
			);
		}
	}
	if (!isChecked) {
		currentFilterParamsState[key] = currentFilterParamsState[key].filter(
			//Uncheck self + children
			(currentValue) =>
				currentValue !== optionValue &&
				(!currentValue?.startsWith || !currentValue.toLowerCase().startsWith(`${optionValue}`.toLowerCase())),
		);
		if (parentOptionValue) {
			currentFilterParamsState[key] = currentFilterParamsState[key].filter((currentValue) =>
				typeof currentValue === 'string'
					? currentValue.toLowerCase() !== parentOptionValue.toLowerCase()
					: currentValue !== parentOptionValue,
			);
		}

		didFilterChange = true;
	}

	return { didFilterChange };
};

export const handleFilterChange = ({ changedFilterState, currentFilterParamsState }) => {
	let didFilterChange = false;

	Object.entries(changedFilterState).forEach(([key, value]) => {
		if (currentFilterParamsState[key] !== value) didFilterChange = true;
		currentFilterParamsState[key] = value;
	});

	return { didFilterChange };
};

export const removeFilter = ({ payload, currentFilterParamsState, defaultFilterParams }) => {
	const { key, value, type } = payload;
	if (
		(type === filterTypes.checkbox || type === filterTypes.groupedCheckbox) &&
		currentFilterParamsState[key]?.filter
	) {
		currentFilterParamsState[key] = currentFilterParamsState[key].filter((currentValue) =>
			typeof currentValue === 'string'
				? currentValue.toLowerCase() !== value.toLowerCase()
				: currentValue !== value,
		);
	} else {
		currentFilterParamsState[key] = defaultFilterParams[key];
	}
};

export const removeFilterFromAppliedList = ({ filterToRemove, appliedFilters }) => {
	const { key, value, type } = filterToRemove;
	return (appliedFilters || []).filter(
		(appliedFilter) =>
			appliedFilter.key !== key || appliedFilter.value !== value || appliedFilter.filter_type !== type,
	);
};

export const resetFilterToDefaultValue = ({ currentSearch, filterKey, filterKeys }) => {
	let didClear = false;
	if (filterKeys) {
		Object.values(filterKeys).forEach((key) => {
			if (currentSearch.params[key] !== currentSearch.defaultFilterParams[key]) {
				currentSearch.params[key] = currentSearch.defaultFilterParams[key];
				didClear = true;
			}
			if (didClear) currentSearch.paramsVersion++;
		});
	} else {
		if (currentSearch.params[filterKey] !== currentSearch.defaultFilterParams[filterKey]) {
			didClear = true;
			currentSearch.params[filterKey] = currentSearch.defaultFilterParams[filterKey];
			currentSearch.paramsVersion++;
		}
	}
	return didClear;
};

export const getFilter = (state, searchListKey, sectionKey, filterKey) => {
	const section = state.searchLists[searchListKey].filterSections.find(({ key }) => key === sectionKey);
	const filter = section.filters.find(({ key }) => key === filterKey);
	return filter;
};

export const getHotfilter = (state, searchListKey, filterKey) =>
	state.searchLists[searchListKey].hotfilters.find(({ key }) => key === filterKey);
