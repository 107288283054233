import jwtDecoder from 'jwt-decode';

/**
 * decode jwt on browser context
 * @throws error on invalid jwt
 * @param {string} jwt to decode
 */
export const decodeJWT = (jwt) => {
	const decoded = jwtDecoder(jwt);
	return decoded;
};


