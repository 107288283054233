import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	bidSales: [],
	highlightTab: false,
	highlightPages: [],
	tabOpen: false,
	currentPage: 1
};

export const observedBidSalesSlice = createSlice({
	name: 'observedBidSales',
	initialState,
	reducers: {
		resetObservedBidSales: (state) => {
			state.bidSales = initialState.bidSales;
		},
		updateObservedBidSalesPage: (state, { payload }) => {
			const bidSalePage = { page: payload.page, bid_sale_ids: payload.bid_sale_ids };
			const index = state.bidSales.findIndex((item) => item.page == payload.page);
			if (index >= 0) {
				state.bidSales[index] = bidSalePage;
			} else {
				state.bidSales.push(bidSalePage);
			}
		},
		highlightBidSale: (state, { payload }) => {
			const { bid_sale_id } = payload;
			const targetPage = state.bidSales.find((bidSalePage) => bidSalePage.bid_sale_ids.includes(bid_sale_id));
			if ( targetPage ) {
				// only highlight the tab if it is not open
				if ( !state.tabOpen ) {
					state.highlightTab = true;
				}
				// only highlight the page if it is not already highlighted, and the user is not currently on that page
				if ( !state.highlightPages.includes(targetPage.page) && targetPage.page != state.currentPage ) {
					state.highlightPages.push(targetPage.page);
				}
			}
		},
		updateTabOpen: (state, { payload }) => {
			state.tabOpen = payload;
			if ( payload ) {
				state.highlightTab = false;
			}
		},
		updateCurrentPage: (state, { payload }) => {
			state.currentPage = payload;
			state.highlightPages = state.highlightPages.filter((page) => page != payload);
		}
	},
});

export const {
	resetObservedBidSales,
	updateObservedBidSalesPage,
	highlightBidSale,
	updateTabOpen,
	updateCurrentPage
} = observedBidSalesSlice.actions;

export default observedBidSalesSlice.reducer;
