import { zIndexMap } from '@Backlot-Cars/archie';
import { createGlobalStyle } from 'styled-components';

const AppGlobalStyles = createGlobalStyle`
	.intercom-lightweight-app .intercom-launcher {
		z-index: ${zIndexMap.intercom};
	}

	@media screen and (max-width: 993px) {
		.intercom-lightweight-app .intercom-launcher, .intercom-dfosxs {
			bottom: 73px !important;
		}
	}
`;

export default AppGlobalStyles;
