/* eslint-disable no-unused-vars */
import BlcMeta from 'components/BlcMeta';
import urlProvider from 'contexts/urls/urlsProvider';
import { BlcLayoutGlobalStyle } from 'partials/BlcLayout/styles';
import PropTypes from 'prop-types';

const BlcLayoutBase = (props) => {
	const classNames = [props.className];
	if (props.fullHeight) classNames.push('full-height-layout');
	const { children, ...layoutProps } = props;
	return (
		<>
			<div id={props.id} className={classNames.join(' ')}>
				<BlcMeta {...props} />
				{props.children}
			</div>
			<BlcLayoutGlobalStyle {...layoutProps} />
		</>
	);
};

BlcLayoutBase.propTypes = {
	/**
	 * Set the Layout children
	 */
	children: PropTypes.node,
	/**
	 * Set the loading bar color
	 */
	loaderColor: PropTypes.string,
	/**
	 * Set the body background color
	 */
	background: PropTypes.string,
	/**
	 * Set the layout global ID
	 */
	id: PropTypes.string,
	/**
	 * Set the layout container class name
	 */
	className: PropTypes.string,
	/**
	 * Various urls to be used on pages like sign up and sign in urls
	 */
	urls: PropTypes.shape({
		signUpUrl: PropTypes.string,
		signInUrl: PropTypes.string,
	}),
	/**
	 * Add a robots meta tag
	 */
	robotsMeta: PropTypes.bool,
};

BlcLayoutBase.defaultProps = {
	id: null,
	children: null,
	className: null,
	loaderColor: 'grayDark',
	robotsMeta: false,
};

export default urlProvider(BlcLayoutBase);
