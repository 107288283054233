import Bugsnag from '@bugsnag/js';
import { getState } from 'store/store';
import { loggedUSerSelector } from 'store/slices/loggedUsers/selector';
import React from 'react';

export function getErrorBoundary() {
	return Bugsnag.getPlugin('react').createErrorBoundary(React);
}

/**
 * Method to notify errors to Bugsnag
 * @param {string or Error} error what you want to notify
 * @param {object} metadata, you can add extra info here, IMPORTANT: object should have at least 2 levels: { metadata: { cause: 'error explanation' } }
 * @param {function} callback, it will be called with event as parameter, so you can overwrite event values from caller
 */
export function notifyError(error, metadata = {}, callback) {
	const errorMessage = formatError(error);

	const appVersion = Bugsnag._client._config.appVersion;
	if (appVersion === 'development') return console.error({ errorMessage });

	const user = loggedUSerSelector(getState());

	Bugsnag.notify(errorMessage, function (event) {
		for (const [key, value] of Object.entries(metadata)) {
			event.addMetadata(key, value);
		}

		if (user?.id) {
			const { id, email, dealership_name } = user;
			event.setUser(id, email, dealership_name);
		}

		callback && callback(event);
	});
}

function formatError(error) {
	if (typeof error === 'string') {
		return error;
	} else if (error.hasOwnProperty('message')) {
		return error.message;
	}

	return safeStringify(error);
}

function safeStringify(error) {
	try {
		return JSON.stringify(error);
	} catch (stringificationError) {
		return stringificationError;
	}
}

export const getSetUserCallback = (user) => (event) => event.setUser(user.id, user.email);
