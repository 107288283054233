export const formatMessage = (message) => message.replace(/<(.*?)>/g, (str) => {
	const action = str.split('|')[0].slice(1);
	const command = action.substring(0, 2);
	const commandId = action.slice(2);
	const label = str.split('|')[1].slice(0, -1);
	switch (command) {
	case '@U':
		return "<a class='normal-link' href='/users/" + commandId + "'>" + label + '</a>';
	case '@V':
		return "<a class='normal-link' href='/inventory/vehicles/" + commandId + "'>" + label + '</a>';
	case '@F':
		return "<a class='normal-link' href='/search?saved_search_id=" + commandId + "'>" + label + '</a>';
	case '@L':
		return "<a class='normal-link' href='" + commandId + "'>" + label + '</a>';
	case '@R':
		const [route, param] = commandId.split(',');
		switch (route) {
		case 'offers_made':
			return "<a class='normal-link' href='/offers/made'>" + label + '</a>';
		case 'offers_received':
			return "<a class='normal-link' href='/inventory/vehicles'>" + label + '</a>';
		case 'checkout':
			return "<a class='normal-link' href='/checkouts/edit'>" + label + '</a>';
		case 'offers_received_for_vehicle':
			return "<a class='normal-link' href='/inventory/vehicles/" + param + "/offers'>" + label + '</a>';
		case 'vehicle':
			return "<a class='normal-link' href='/inventory/vehicles/" + param + "'>" + label + '</a>';
		}
	case '@S':
		switch (commandId) {
		case 'strong':
			return '<strong>' + label + '</strong>';
		case 'muted':
			return "<span class='muted'>" + label + '</span>';
		}
	}
});
