/* eslint-disable camelcase */
import { decodeJWT } from 'helpers/jwt/decode';
import getLoginURL from 'helpers/user/getLoginURL';

export default async (jwt, extraUserData, ctx) => {
	if (jwt) {
		const {
			auth,
			email,
			user_hash,
			user_id,
			user: userName,
			created_at,
			intercom_app_id,
			account_id,
			is_account_master,
			dealership_name,
			city,
			state,
			carwave,
			normal_image,
			admin_user_id,
			on_behalf_of,
		} = decodeJWT(jwt) || {};

		const isMasterUserOnBehalfOfOther = Boolean(on_behalf_of?.user_id);
		let masterUserData = null;
		if (isMasterUserOnBehalfOfOther) {
			masterUserData = {
				user_id,
				user: userName,
				dealership_name,
				normal_image,
			};
		}

		const user = {
			id: user_id,
			auth,
			jwt,
			email,
			user_hash,
			user_id,
			user: userName,
			created_at,
			intercom_app_id,
			account_id,
			is_account_master,
			dealership_name,
			city,
			state,
			carwave,
			normal_image,
			admin_user_id,
			on_behalf_of: on_behalf_of || null,
			...extraUserData,
			// when a master user is impersonating another user we will overwrite user data with on_behalf_of data..
			// master user data will be on masterUserData (only populated when master user is impersonating another user)
			masterUserData,
			...on_behalf_of, // <-- this will overwrite user data like userId, user, dealership and image
		};
		if (ctx) {
			user.loginURL = await getLoginURL(ctx, true);
		}

		return user;
	} else {
		return {
			auth: false,
			jwt: null,
			loginURL: await getLoginURL(ctx, true),
		};
	}
};
