import { getLiveOvertimeBubble } from '../bubbles';
import getLiveOfferStatus from '../live';

const getOvertimeOfferStatus = (currentUserTopOfferer, bestOfferUserData, hasOffer, currentUserHasOffered, isEnded) => {
	if (isEnded) {
		return {
			...getLiveOvertimeBubble(currentUserTopOfferer, currentUserHasOffered),
			...bestOfferUserData,
			extended: true,
			bidActions: true,
		};
	} else {
		return getLiveOfferStatus(currentUserTopOfferer, bestOfferUserData, hasOffer, currentUserHasOffered);
	}
};

export default getOvertimeOfferStatus;
