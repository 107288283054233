import STATUS_CODES from 'constants/statusCodes';
import buildUser from 'helpers/user/buildUser';
import { clearUser, setLoggedUserFields } from 'store/slices/loggedUsers/loggedUserSlice';
import { getDispatcher } from 'store/store';
import expired from '../../jwt/expired';
import { withCookiesRestClient } from '../../restclient';
import { getSignInUrl } from 'helpers/externalRoutes';

const refreshJWT = async (config, internalRestClientInstance) => {
	const axiosInstance = internalRestClientInstance._axios;

	if (
		config.headers &&
		config.headers['X-User-JWT'] &&
		!process.env.ALLOW_CROSS_DOMAIN_LOGIN &&
		expired(config.headers['X-User-JWT'])
	) {
		const defaultJWT = axiosInstance.defaults.headers['X-User-JWT'];
		// this is to ensure that default config overwrites headers when headers are expired.
		if (defaultJWT && !expired(defaultJWT)) {
			config.headers['X-User-JWT'] = defaultJWT;
			return config;
		}

		const res = await withCookiesRestClient.post(
			'/users/cookie',
			{},
			{ Accept: 'application/json; application/vnd.backlotcars.v3' },
		);
		const dispatch = getDispatcher();

		if (res.status == STATUS_CODES.OK) {
			config.headers['X-User-JWT'] = res.data.jwt;
			axiosInstance.defaults.headers['X-User-JWT'] = res.data.jwt;
			const user = await buildUser(res.data.jwt);
			dispatch && dispatch(setLoggedUserFields({ loggedUser: user }));
		} else {
			dispatch && dispatch(clearUser());
			const signInRedirection = getSignInUrl(window?.location?.href);
			window.location.assign(signInRedirection);
		}
	}

	return config;
};

export default refreshJWT;
