import { Body, Button, Headline } from '@Backlot-Cars/archie';
import PublicLayout from 'components/PublicSiteLayout/Base';
import { redirect } from 'helpers/redirect';
import ErrorImage from './ErrorImage';
import { ButtonContainer, Container, Content, ContentContainer } from './styles';

const OpenlaneError = ({ statusCode, title, description }) => (
	<PublicLayout>
		<Container>
			<ContentContainer>
				<Content>
					<ErrorImage statusCode={statusCode} />
					<Headline size="H3" padding="20px" textAlign="center">
						{title}
					</Headline>
					<Body textAlign="center">{description}</Body>
					<ButtonContainer>
						<Button onClick={() => redirect('/')}>Back to OPENLANE</Button>
					</ButtonContainer>
				</Content>
			</ContentContainer>
		</Container>
	</PublicLayout>
);

export default OpenlaneError;
