import { createSlice } from '@reduxjs/toolkit';
import { isOlderVersion } from 'helpers/timedSales/versionChecker';
import { calculateSummaryFromList } from 'store/slices/offerSummary/helper';
import { loadBuyerInitialOfferSummary } from 'store/slices/offerSummary/offerSummaryThunk';
import { isCurrentUserTheTopOfferer } from 'store/slices/offerSummary/helper';

const initialState = {
	bidSalesThatUserHasOfferer: [],
	userIds: [], // logged user id, or subordinates id,
	dealershipsId: [],
	loading: false,
	success: null,
	stats: {
		leaderCount: 0,
		outbiddedCount: 0,
		wonCount: 0,
		lostCount: 0,
		counterCount: 0,
	},
};

export const buyerOfferSummarySlice = createSlice({
	name: 'buyerOfferSummary',
	initialState,
	reducers: {
		updateOfferSummary: (state, { payload }) => {
			const { bidSaleId, topOffererId, topOffererDealershipId, auctionStatus, fromUserBid, version } = payload;

			const { userIds, dealershipsId } = state;

			const isUserTheTopOfferer = isCurrentUserTheTopOfferer({
				topOffererId,
				topOffererDealershipId,
				userIds,
				dealershipsId,
			});

			let foundIndex = state.bidSalesThatUserHasOfferer.findIndex((bidSale) => bidSale.bid_sale_id === bidSaleId);
			const wasElementFound = foundIndex > -1;

			if (wasElementFound) {
				const elementToUpdate = state.bidSalesThatUserHasOfferer[foundIndex];
				if (
					!elementToUpdate.version ||
					!isOlderVersion({ incomingChanges: { version }, storedState: elementToUpdate })
				) {
					state.bidSalesThatUserHasOfferer[foundIndex] = {
						...elementToUpdate,
						top_offerer_id: topOffererId,
						top_offerer_dealership_id: topOffererDealershipId,
						auction_status: auctionStatus,
						version,
					};
				}
			}

			// We force push if fromUserBid is true because if user makes a bid for a vehicle with higher autobid
			// WS sends the message of autobid increase and not the current user bid stats will not include that current user was outbidded
			if (!wasElementFound && (isUserTheTopOfferer || fromUserBid)) {
				state.bidSalesThatUserHasOfferer.push({
					bid_sale_id: bidSaleId,
					top_offerer_id: topOffererId,
					top_offerer_dealership_id: topOffererDealershipId,
					auction_status: auctionStatus,
					version,
				});
			}

			state.stats = calculateSummaryFromList({
				bidSalesThatUserHasOfferer: state.bidSalesThatUserHasOfferer,
				userIds,
				dealershipsId,
			});
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadBuyerInitialOfferSummary.pending, (state, { meta }) => {
			const { silentUpdateForReloading } = meta.arg;
			if (silentUpdateForReloading) return;
			state.loading = true;
			state.success = null;
		});
		builder.addCase(loadBuyerInitialOfferSummary.fulfilled, (state, { payload }) => {
			const { bidSalesArr, stats, userIds, dealershipsId } = payload;

			state.bidSalesThatUserHasOfferer = bidSalesArr;
			state.stats = stats;
			state.userIds = userIds;
			state.dealershipsId = dealershipsId;
			state.loading = false;
			state.success = true;
		});
		builder.addCase(loadBuyerInitialOfferSummary.rejected, (state, { meta }) => {
			const { silentUpdateForReloading } = meta.arg;
			state.loading = false;
			// silent updates keeps old value on fail
			if (silentUpdateForReloading) return;
			state.success = false;
		});
	},
});

export const { updateOfferSummary } = buyerOfferSummarySlice.actions;

export default buyerOfferSummarySlice.reducer;
