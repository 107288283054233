const storeModule = {
	internalStore: null,
	get store() {
		return this.internalStore;
	},

	set initializeStore(store) {
		this.internalStore = store;
	},
};

export const getState = () => storeModule.store?.getState();
export const getDispatcher = () => storeModule.store?.dispatch;
export const setStore = (store) => {
	storeModule.initializeStore = store;
};