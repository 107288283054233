import { getLiveOvertimeBubble, startingBidBubble } from '../bubbles';

const getLiveOfferStatus = (currentUserTopOfferer, bestOfferUserData, hasOffer, currentUserHasOffered) => {
	if (hasOffer) {
		return {
			...getLiveOvertimeBubble(currentUserTopOfferer, currentUserHasOffered),
			...bestOfferUserData,
			bidActions: true,
		};
	} else {
		return { ...startingBidBubble, bidActions: true };
	}
};

export default getLiveOfferStatus;
