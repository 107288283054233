import { colors } from '@Backlot-Cars/archie';
import { size, unit } from 'public/styles';
import { createGlobalStyle, css } from 'styled-components';

export const BlcLayoutGlobalStyle = createGlobalStyle`

* {
	box-sizing: border-box;
	font-smoothing: antialiased;
	text-stroke: 0.45px rgba(0, 0, 0, 0.1);
}


${({ fullHeight, background, loaderColor }) =>
		css`
		body > #__next {
			${fullHeight
		? `
		height: 100%;
		display: flex;
		flex-direction: column;
	`
		: ''}
		}

		.full-height-layout {
			${fullHeight
		? `
		flex: 1;
		min-height: 0;
		overflow: auto;
	`
		: ''}
		}

		html {
			scroll-behavior: smooth;
			${fullHeight ? `height: 100%;` : ''}
		}

		body {
			margin: 0;
			overflow-x: hidden;
			padding: 0;
			${background ? `background: ${background};` : ''}
			${fullHeight ? `height: 100%;` : ''}
		}

		#nprogress .bar {
			background: ${colors[loaderColor]};
			height: ${size.border.mxl};
			left: 0;
			pointer-events: none;
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 11;
		}
		#nprogress .bar .peg {
			box-shadow: 0 0 ${size.border.mxl} ${colors.black};
			display: block;
			height: 100%;
			opacity: 1;
			position: absolute;
			right: 0;
			transform: rotate(3deg) translate(0px, ${size.border.mxl * -2});
			width: ${unit(12)};
		}
	`}
`;
