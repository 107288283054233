import { useRouter } from 'next/router';

const isWebappInPartnerPlatform = () => {
	try {
		return typeof window !== 'undefined' && window.location != window.parent.location;
	} catch {
		return false;
	}
};

const useIsPartnerQueryParam = (partnerName) => {
	const { query } = useRouter();
	const isPartnerQueryParam = !!query?.partner_name && query.partner_name.toLowerCase() === partnerName;
	return isPartnerQueryParam;
};

const useIsPartner = (partnerName) => {
	const isPartnerQueryParam = useIsPartnerQueryParam(partnerName);
	return isPartnerQueryParam || isWebappInPartnerPlatform();
};

export default useIsPartner;
