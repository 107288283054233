import styled from 'styled-components';

export const SvgPositioner = styled.div`
	position: absolute;
	bottom: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;
`;
