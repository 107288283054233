import { notifyError } from 'helpers/errorNotifier';
import PARTNERS from 'helpers/partners';
import { ScriptLoader } from 'helpers/scriptLoader';
import useIsPartner from 'hooks/useIsPartner';
import { useRouter } from 'next/router';
import { getStockwaveScriptURL, STOCKWAVE_PAGES } from '../StockwaveHelpers';

const StockwaveScript = () => {
	const stockwaveScriptUrl = getStockwaveScriptURL();
	const isStockwavePartner = useIsPartner(PARTNERS.stockwave);
	const { pathname, query } = useRouter();
	const onLoadedScript = () => {
		try {
			localStorage['stockwave-dev-debug'] = false;
			window.name = 'iframe-browser';
			console.info('Initialize stockwave');
			window.stockwave.initialize();
		} catch (error) {
			notifyError('Error loading stockwave script');
		}
	};

	const disableScript = query?.disable_partner_script === 'true';

	if (isStockwavePartner && STOCKWAVE_PAGES.includes(pathname) && !disableScript) {
		return <ScriptLoader id="stockwave-script" url={stockwaveScriptUrl} onLoadedScript={onLoadedScript} />;
	}
	return null;
};

export default StockwaveScript;
