import { useEffect, useState } from 'react';

const loadScript = (id, url, onLoadScript) => {
	let script = document.getElementById(id);
	if (!script) {
		script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = url;
		script.id = id;
		script.async = true;
		script.defer = true;
		script.onload = function () {
			if (onLoadScript) onLoadScript();
		};
		document.body.appendChild(script);
	} else if (onLoadScript) {
		onLoadScript();
	}
	return script;
};

const ScriptLoader = ({ id, url, onLoadedScript }) => {
	const [loadedScript, setLoadedScript] = useState(false);
	useEffect(() => {
		if (!loadedScript) {
			loadScript(id, url, onLoadedScript);
			setLoadedScript(true);
		}
	}, [id, url, onLoadedScript, loadedScript]);

	return <></>;
};

export { loadScript, ScriptLoader };
